import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const spring = {
  type: 'spring',
  damping: 25,
  stiffness: 120
};

const SmallLeaderboard = ({ activePlayers, currentPlayer, playersDetails, scores = {}, colors }) => {
  const [players, setPlayers] = useState({});
  const [sortedPlayers, setSortedPlayers] = useState([]);
  useEffect(() => {
    activePlayers.forEach((player) => {
      return setPlayers((prev) => {
        const prevScore = prev[player] ? prev[player].score : 0;
        return {
          ...prev,
          [player]: {
            id: player,
            // color: getRandomColor(),
            currentScore: scores[player] ? scores[player] : 0,
            prevScors: prevScore,
            details: playersDetails[player],
            isCurrentPlayer: player === currentPlayer,
            color: colors[player]
          }
        };
      });
    });
    // setTimeout(() => setColors(shuffle(colors)), 1000);
  }, [activePlayers, currentPlayer, playersDetails]);
  useEffect(() => {
    const playersArr = Object.entries(players).map((e) => e[1]);
    setSortedPlayers(
      playersArr.sort((player1, player2) => player2.currentScore - player1.currentScore)
    );
  }, [scores, players]);

  return (
    <ul className="w-full opacity-100">
      {sortedPlayers.map((player, i) => {
        return (
          <motion.li
            key={player.id}
            layout
            transition={spring}
            className="w-full m-2 h-8 rounded-xl text-white bg-gray-700 flex items-center">
            <span
              className="flex w-5 h-8 rounded-tl-xl rounded-br-xl justify-center items-center text-xs"
              style={{
                background: player.color
              }}>
              {i + 1}
            </span>
            <span className="px-2 text-xs flex-1 truncate">{player.details.email}</span>
            <div className="px-2  font-bold">{player.currentScore}</div>
          </motion.li>
        );
      })}
    </ul>
  );
};

export default SmallLeaderboard;
