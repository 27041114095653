export const INPUT_RULE_REQUIRED_NUMBER = {
    value: true,
    message: 'Please add a number'
}

export const INPUT_RULE_PATTERN_NUMBER = {
    value: /^(0|[1-9]\d*)$/,
    message: 'Please use numbers only'
}

export const getInputRuleMinNumber = ({ value, max }) => ({
    value,
    message: `The value must be between 1 and ${max}`
})

export const getInputRuleMaxNumber = ({ value, max }) => ({
    value,
    message: `The value must be between 1 and ${max}`
})