import React from 'react';
import { Link } from 'react-router-dom';
const SecondaryButton = ({ action, type, title, link, width, Icon, disabled }) => {
  const classList = `${
    width ? width : 'w-full'
  } bg-transparent text-red-500 hover:bg-opacity-75 justify-center text-center items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md outline-none focus:outline-none rounded-2xl`;
  if (link) {
    return (
      <Link to={link} className={classList}>
        {Icon && <span className={'mr-1'}>{Icon}</span>}
        {title}
      </Link>
    );
  }

  return (
    <button disabled={disabled} type={type} onClick={action || (() => {})} className={classList}>
      {Icon && <span className={'mr-1'}>{Icon}</span>}
      {title}
    </button>
  );
};

export default SecondaryButton;
