import MainButton from '../../ui/buttons/MainButton';
import Input from '../Input';
import { MailIcon, LockClosedIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addTokens } from '../../../redux/modules/user/actions';
const FirstLoginForm = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const callback = () => {
    navigate('/dashboard');
  };
  const submit = (data) => {
    dispatch(
      addTokens({
        id: user.uid,
        accessToken: data.accessToken,
        schoolUrl: data.schoolUrl,
        callback: callback
      })
    );
  };
  return (
    <>
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 w-3/4">
        <div className=" w-full space-y-8">
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <Input
                        Icon={<MailIcon className="h-5 w-5 text-gameBlue" />}
                        label="schoolUrl"
                        htmlFor="schoolUrl"
                        placeholder="https://school.instructure.com/"
                        onChange={(e) => onChange(e)}
                        width="w-full"
                      />
                    );
                  }}
                  name="schoolUrl"
                  rules={{
                    pattern: {
                      value:
                        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i,
                      message: 'Please use a valid url'
                    },
                    required: {
                      value: true,
                      message: 'Please add your school URL'
                    }
                  }}
                />
              </div>
              <p className="text-xs text-red-500 " style={{ minHeight: '1rem' }}>
                {errors.schoolUrl && errors.schoolUrl.message}
              </p>
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <Input
                        Icon={<LockClosedIcon className="h-5 w-5 text-gameBlue" />}
                        label="accessToken"
                        htmlFor="accessToken"
                        placeholder="Your access token"
                        onChange={(e) => onChange(e)}
                        width="w-full"
                      />
                    );
                  }}
                  name="accessToken"
                  rules={{
                    required: {
                      value: true,
                      message: 'Please add an Access Token from Canvas'
                    }
                  }}
                />
              </div>
              <p className="text-xs text-red-500 " style={{ minHeight: '1rem' }}>
                {errors.password && errors.password.message}
              </p>
            </div>

            <div>
              <MainButton type="submit" title="Save" width="w-full" action={handleSubmit(submit)} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default FirstLoginForm;
