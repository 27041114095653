import Phaser from 'phaser';
import { WIDTH, HEIGHT, ROOT_ID } from './utils/constants';
import { MainScene } from './scenes/MainScene';

/**
 * Game manager is a main phaser game class
 *
 * Params:
 * run: boolean
 * maxScore: number - Maximum score of the quiz
 * players: { id: string, main: boolean, score: number }[] - Array of all players with their current scores
 * prev: {
 *  maxScore: number
 *  players: { id: string, main: boolean, score: number }[]
 * } - previous state to calculate correct starting position
 */
export class GameManager {
  constructor(params) {
    this.game = new Phaser.Game({
      type: Phaser.AUTO,
      width: WIDTH,
      height: HEIGHT,
      parent: ROOT_ID,
      scene: MainScene,
      transparent: true,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_HORIZONTALLY
      }
    });

    const interval = setInterval(() => {
      const scenes = this.game.scene.getScenes(true);
      if (scenes.length > 0) {
        clearInterval(interval);
        this.onBoot(params);
      }
    });
  }

  onBoot = (params) => {
    this.updateParams(params);
  };

  updateParams(params) {
    this.game.scene.getScenes(true).forEach((scene) => {
      scene.updateParams(params);
    });
  }
}
GameManager.displayName = 'GameManager';
