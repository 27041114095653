import MainButton from '../../ui/buttons/MainButton';
import Input from '../Input';
import { MailIcon } from '@heroicons/react/solid';
// import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendRecoveryEmail } from '../../../redux/modules/auth/actions';
const RestePasswordEmailForm = () => {
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState('');
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const submit = (data) => {
    setEmailSent(`Account recovery email sent to ${data.email}`);
    dispatch(sendRecoveryEmail({ email: data.email }));
  };

  return (
    <>
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 w-full">
        <div className="max-w-6xl w-full space-y-8">
          {!emailSent ? (
            <form className="mt-8 space-y-6" action="#" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <Controller
                    control={control}
                    render={({ field: { onChange } }) => {
                      return (
                        <Input
                          Icon={<MailIcon className="h-5 w-5 text-gameBlue" />}
                          label="email"
                          htmlFor="email"
                          placeholder="account@gamify.com"
                          onChange={(e) => onChange(e)}
                        />
                      );
                    }}
                    name="email"
                    rules={{
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: 'Please use a valid email address'
                      },
                      required: {
                        value: true,
                        message: 'Please add an email address'
                      }
                    }}
                  />
                </div>
                <p className="text-xs text-red-500 " style={{ minHeight: '1rem' }}>
                  {errors.email && errors.email.message}
                </p>
              </div>

              <div>
                <MainButton
                  type="submit"
                  title="Send recovery email"
                  width="w-full"
                  action={handleSubmit(submit)}
                />
              </div>
            </form>
          ) : (
            <div className="w-full flex justify-center items-center bg-gameBlue p-10 rounded-xl font-bold text-white">
              {emailSent}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default RestePasswordEmailForm;
