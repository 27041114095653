import React from 'react';
import { useSelector } from 'react-redux';
import loading from '../../../assets/images/loading.gif';
const Loader = () => {
  const { loading: authLoading } = useSelector((state) => state.auth);
  const { loading: canvasLoading } = useSelector((state) => state.canvas);
  const { loading: userLoading } = useSelector((state) => state.user);
  const { loading: gamesLoading } = useSelector((state) => state.games);

  if (authLoading || canvasLoading || userLoading || gamesLoading) {
    return (
      <div className="absolute inset-0 bg-transparent z-50">
        <div className="flex justify-center items-center h-full ">
          <div className="sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-white shadow-lg rounded-xl flex flex-col p-10 justify-center items-center">
            <div className="p-10 font-bold text-3xl ">
              {authLoading || canvasLoading || userLoading || gamesLoading}
            </div>
            <img src={loading} alt="loading" className="w-full h-auto" />
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default Loader;
