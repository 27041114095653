import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainButton from '../../components/ui/buttons/MainButton';
import {
  archiveGame,
  endGame,
  fetchGames,
  fetchActiveGames,
  fetchPlayRoom,
  // fetchPlayingGame,
  startGame,
  startNewGameroom
} from '../../redux/modules/games/actions';
import ClipboardCopy from '../../components/ui/ClipboardCopy';
import gamifyLogo from '../../assets/images/logo-big.png';
import QRCode from 'qrcode.react';
import Modal from '../../components/ui/Modals/Modal';
import { showModal } from '../../redux/modules/ui/actions';
import SecondaryButton from '../../components/ui/buttons/SecondaryButton';
const SingleGame = () => {
  
  const dispatch = useDispatch();
  const { selectedGame, activeGames, activePlayRoom } = useSelector((state) => state.games);
  const [isGameActive, setIsGameActive] = useState({});
  const adminTVLink = `${process.env.REACT_APP_TV_APP_URL}/${isGameActive?.roomId}`

  const goToAdminTV = () => window.open(adminTVLink,'_blank')

  useEffect(() => {
    if (activeGames && selectedGame && activeGames[selectedGame.gameId]) {
      setIsGameActive(activeGames[selectedGame.gameId]);
    } else {
      setIsGameActive({});
    }
  }, [activeGames, selectedGame]);
  useEffect(() => {
    if (isGameActive.roomId) {
      dispatch(fetchPlayRoom(isGameActive.roomId));
    }
  }, [isGameActive.roomId]);
  useEffect(() => {
    if (!activePlayRoom?.active) {
      dispatch(fetchGames());
      dispatch(fetchActiveGames());
    }
  }, [activePlayRoom?.active]);

  if (!selectedGame) {
    return <></>;
  }
  const stopGameCallback = () => {
    dispatch(fetchGames());
    dispatch(fetchActiveGames());
  };
  return (
    <div className="w-full">
      <section className="w-full lg:w-1/2">
        <div>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            {selectedGame.coverImage ? (
              <img
                className={`max-h-48 w-auto ${selectedGame.archived && 'grayscale'}`}
                src={selectedGame.coverImage}
                alt={selectedGame.name}
              />
            ) : (
              <img
                className={`max-h-48 w-auto ${selectedGame.archived && 'grayscale'}`}
                src={gamifyLogo}
                alt={selectedGame.name}
              />
            )}
          </div>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                <h1 className="text-2xl font-bold text-gray-900 ">
                  {selectedGame.name} {selectedGame.archived && '(Archived)'}
                </h1>
              </div>
            </div>

            <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 ">
                {selectedGame.name} {selectedGame.archived && '(Archived)'}
              </h1>
            </div>
          </div>
        </div>
        <div className="mt-4 max-w-5xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
          {!isGameActive || !isGameActive.roomId ? (
            <div className="flex justify-start">
              <MainButton
                width={'w-3/4'}
                title="Open new game"
                action={() => dispatch(startNewGameroom({ game: selectedGame }))}
              />
            </div>
          ) : (
            <>
              <ClipboardCopy
                copyText={adminTVLink}
              />
              <div className='pt-5'>
              <MainButton title={'Open'} action={goToAdminTV} />
              </div>
              {activePlayRoom?.activePlayers && activePlayRoom?.activePlayers.length > 0 && (
                <div className="flex justify-start  mt-4">
                  <MainButton
                    width={'w-3/4'}
                    // disabled={activePlayRoom.started}
                    title={activePlayRoom.started ? 'Stop Game' : 'Start Game'}
                    action={() =>
                      activePlayRoom.started
                        ? dispatch(
                            endGame({ roomId: isGameActive?.roomId, callback: stopGameCallback })
                          )
                        : dispatch(
                            startGame({
                              roomId: isGameActive?.roomId
                            })
                          )
                    }
                  />
                </div>
              )}

              <Modal>
                <QRCode
                  value={`${window.location.host}/game/play/${selectedGame.gameId}/${isGameActive.roomId}`}
                  renderAs="canvas"
                  size={460}
                />
              </Modal>
            </>
          )}
        </div>
        <div className="mt-4 max-w-5xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
          <h3 className="text-base text-gray-500">Game Type</h3>
          <p className="text-xl text-gameBlue font-bold">{selectedGame.gameType}</p>
        </div>
        <div className="mt-4 max-w-5xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
          <h3 className="text-base text-gray-500">Prize</h3>
          <p className="text-xl text-gameBlue font-bold">{selectedGame.prize}</p>
        </div>
        <div className="mt-4 max-w-5xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
          <h3 className="text-base text-gray-500">Number of questions</h3>
          <p className="text-xl text-gameBlue font-bold">{selectedGame.questions.length}</p>
        </div>
      </section>

      {!selectedGame.archived ? (
        <SecondaryButton
          title="Archive game"
          action={() => dispatch(archiveGame({ gameId: selectedGame.gameId, archiveState: true }))}
        />
      ) : (
        <SecondaryButton
          title="Activate game"
          action={() => dispatch(archiveGame({ gameId: selectedGame.gameId, archiveState: false }))}
        />
      )}
    </div>
  );
};

export default SingleGame;
