import React from 'react';
import loading from '../../../assets/images/loading.gif';
const WaitForGame = () => {
  return (
    <div className="w-full flex justify-center items-center h-full flex-col">
      <h2 className="py-10 text-3xl font-bold">Waiting for the game to start</h2>
      <img src={loading} alt="loading" className="w-full h-auto" />
    </div>
  );
};

export default WaitForGame;
