import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  changeCurrentQuestion,
  fetchPlayingGame,
  fetchPlayRoom,
  getPlayersDetails,
  joinGame
} from '../../../redux/modules/games/actions';
import GamePreview from '../../../components/game/GamePreview';
import QuizGame from '../../../components/game/QuizGame';
import StudentLayout from '../../../layouts/StudentLayout';
import WaitForGame from '../../../components/game/WaitForGame';
import LeaderBoard from '../../../components/game/Leaderboard';
import { RaceGame } from '../../../components/game/RaceGame2';
import { getCurrentMaxScore } from './utils';
import SmallLeaderboard from '../../../components/game/SmallLeaderboard';
import { AnimatePresence, motion } from 'framer-motion';
import lodash from 'lodash';
const PlayGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, room } = useParams();
  const { playingGame, activePlayRoom, playerDetails, showGamesScreen } = useSelector(
    (state) => state.games
  );
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [startGame, setStartGame] = useState(false);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const currentGameUrl = window.location.pathname;

  const currentMaxScore = getCurrentMaxScore(
    playingGame?.questions?.length,
    Object.values(activePlayRoom.scores || {}),
    activePlayRoom.currentQuestion + 1
  );
  const players = Object.entries(activePlayRoom.scores || {}).map(([id, score]) => {
    return {
      id,
      score,
      main: user?.uid === id
    };
  });

  useEffect(() => {
    dispatch(fetchPlayingGame(id));
    dispatch(fetchPlayRoom(room));
  }, []);
  useEffect(() => {
    dispatch(getPlayersDetails({ players: activePlayRoom.allPlayers }));
  }, [activePlayRoom]);
  const handleStartGame = () => {
    setStartGame(true);
    dispatch(joinGame({ 
      userId: user?.uid, roomId: room, 
      room: activePlayRoom,
      userEmail:user?.email,
    }));
    dispatch(changeCurrentQuestion(0));
  };
  const endGame = () => {
    setStartGame(false);
    dispatch(changeCurrentQuestion(0));
  };
  const handleAccount = () => {
    navigate(`/signin-s?redirectTo=${currentGameUrl}`);
  };
  if (!startGame && !activePlayRoom.started) {
    return (
      <StudentLayout>
        <GamePreview
          playingGame={playingGame ? playingGame : {}}
          setStartGame={isAuthenticated ? handleStartGame : handleAccount}
        />
      </StudentLayout>
    );
  }

  if (!activePlayRoom.started || lodash.isEmpty(playingGame)) {
    return (
      <StudentLayout>
        <WaitForGame />
      </StudentLayout>
    );
  }

  if (!activePlayRoom.endDate && activePlayRoom.active) {
    return (
      <>
        <AnimatePresence>
          {isAnswerSelected && (
            <motion.div className="absolute z-20 bottom-0">
              <SmallLeaderboard
                activePlayers={activePlayRoom.activePlayers}
                currentPlayer={user.id}
                playersDetails={playerDetails}
                scores={activePlayRoom.scores}
                colors={activePlayRoom.colors}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <StudentLayout>
          {players.length > 0 && (
            <RaceGame run={showGamesScreen} maxScore={currentMaxScore} players={players} />
          )}
          <QuizGame
              question={playingGame?.questions[activePlayRoom.currentQuestion]}
              totalQuestions={playingGame?.questions.length}
              currentQuestion={activePlayRoom?.currentQuestion + 1}
              questionTime={activePlayRoom.questionState}
              endGame={endGame}
              roomId={room}
              playerId={user?.uid}
              playerScore={activePlayRoom.scores && activePlayRoom.scores[user?.uid]}
              setIsAnswerSelected={setIsAnswerSelected}
            />
        </StudentLayout>
      </>
    );
  }

  return (
    <StudentLayout>
      <LeaderBoard
        scores={activePlayRoom.scores}
        players={activePlayRoom.allPlayers}
        playerDetails={playerDetails}
      />
    </StudentLayout>
  );
};

export default PlayGame;
