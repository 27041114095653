import React from 'react';
import Navbar from '../../components/ui/Navbar';
const BlankLayout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      {children}
    </div>
  );
};

export default BlankLayout;
