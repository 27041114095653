import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import Input from '../Input';
import DropdownInput from '../DropdownInput';

import MainButton from '../../ui/buttons/MainButton';
import { useSelector, useDispatch } from 'react-redux';
import { fetchQuizQuestions } from '../../../redux/modules/canvas/actions';
import _ from 'lodash';
import { createNewGame } from '../../../redux/modules/games/actions';
import { hideModal } from '../../../redux/modules/ui/actions';
import TextError from '../../../components/ui/texts/TextError'
import { INPUT_RULE_PATTERN_NUMBER, INPUT_RULE_REQUIRED_NUMBER, getInputRuleMaxNumber, getInputRuleMinNumber } from '../../../constants/inputRules';

const CreateGameForm = ({ courseId }) => {
  const dispatch = useDispatch();
  const {
    modal: { data, visible }
  } = useSelector((state) => state.ui);
  const { userLocal } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  const { questions, loading } = useSelector((state) => state.canvas);
  const [coverImage, setCoverImage] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  useEffect(() => {
    if (data.id && visible) {
      dispatch(
        fetchQuizQuestions({
          schoolUrl: userLocal.schoolUrl,
          accessToken: userLocal.accessToken,
          courseId: courseId,
          quizId: data.id,
          numberOfQuestions: data.numberOfQuestions
        })
      );
    }
  }, [data.id, visible]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setCoverImage(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
        })
      );
    }
  });
  const callback = () => {
    setCoverImage([]);
    dispatch(hideModal());
  };

  const submit = (values) => {
    const usedQuestions = _.shuffle(questions).slice(0, values.numberOfQuestions);
    dispatch(
      createNewGame({
        title: values.title,
        coverImage: coverImage[0],
        quizId: data.id,
        questions: usedQuestions,
        owner: user.uid,
        gameType: values.gameType,
        prize: values.prize,
        durationBetweenAnswers:values.durationBetweenAnswers,
        courseId: courseId,
        callback: callback
      })
    );
  };
  const fileRejectionItemsModel = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul className="w-full text-center">
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === 'file-invalid-type' ? 'The file must be 3D model' : e.message}
            <br />
            {file.path}
          </li>
        ))}
      </ul>
    </div>
  ));
  const fileAccpetedItemsCover = coverImage.map((file) => {
    return (
      <div
        className="text-gameBlue font-bold text-xs text-center flex flex-col justify-center items-center"
        key={file.path}>
        {file.path}
        <img src={file.preview} alt="preview-coverImage" className="h-32 w-auto" />
      </div>
    );
  });
  if (!loading) {
    return (
      <div className="w-full flex justify-center items-center flex-col">
        <h3 className="text-4xl font-semibold my-2">Gamify your content</h3>
        <div className="w-full bg-gray-200 p-2 rounded-xl">
          <div className="text-center p-2">Quiz found: {data.title}</div>
          <div className="text-center p-2">Questions available: {data.numberOfQuestions}</div>
        </div>
        <form className=" w-full px-4">
          <div className="mt-2  sm:col-span-2">
            <div
              className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              {...getRootProps()}>
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true">
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex justify-center items-center text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-gameBlue  focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 ">
                    <span>Upload a file</span>
                    <input
                      {...getInputProps()}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                {fileRejectionItemsModel}
                {fileAccpetedItemsCover}
              </div>
            </div>
          </div>

          <Controller
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <Input
                  ref={ref}
                  label="Quiz Name"
                  htmlFor="title"
                  placeholder="Quiz Name"
                  onChange={(e) => onChange(e)}
                  width="w-full"
                  defaultValue={data.title}
                />
              );
            }}
            name="title"
            defaultValue={data.title}
          />
          <Controller
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <Input
                  ref={ref}
                  labelClassName=""
                  label="Total questions"
                  htmlFor="numberOfQuestions"
                  placeholder="Total questions"
                  defaultValue={10}
                  onChange={(e) => onChange(e)}
                  width="w-full"
                />
              );
            }}
            name="numberOfQuestions"
            rules={{
              required: INPUT_RULE_REQUIRED_NUMBER,
              pattern: INPUT_RULE_PATTERN_NUMBER,
              min: {
                value: 1,
                message: `The value must be between 1 and ${data.numberOfQuestions}`
              },
              max: {
                value: data.numberOfQuestions,
                message: `The value must be between 1 and ${data.numberOfQuestions}`
              }
            }}
            defaultValue={10}
          />
          <TextError error={errors?.numberOfQuestions?.message} />

          <Controller
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <Input
                  ref={ref}
                  label="Seconds to answer each question"
                  htmlFor="durationBetweenAnswers"
                  placeholder="Seconds to answer each question"
                  defaultValue={5}
                  onChange={(e) => onChange(e)}
                  width="w-full"
                  labelClassName=""
                />
              );
            }}
            name="durationBetweenAnswers"
            rules={{
              required: INPUT_RULE_REQUIRED_NUMBER,
              pattern: INPUT_RULE_PATTERN_NUMBER,
              min: {
                value: 5,
                message: `The value must be between 5 and 30`
              },
              max: {
                value: 30,
                message: `The value must be between 5 and 30`
              }
            }}
            defaultValue={5}
          />
          <TextError error={errors?.durationBetweenAnswers?.message} />

          <Controller
            control={control}
            render={({ field }) => {
              return (
                <DropdownInput
                  {...field}
                  ref={field.ref}
                  label="gameType"
                  htmlFor="gameType"
                  width="w-full"
                  defaultValue={'Racing Game'}
                  options={[
                    {
                      value: '',
                      hidden: true,
                      disabled: true,
                      label: 'Select Game Type'
                    },
                    { value: 'Racing Game', disabled: false, label: 'Racing Game' },
                    {
                      value: 'Football Game',
                      disabled: true,
                      label: 'Football Game - Comming soon'
                    }
                  ]}
                />
              );
            }}
            name="gameType"
            rules={{
              required: {
                value: true,
                message: 'Please choose a game type'
              }
            }}
            defaultValue={'Racing Game'}
          />
          <p className="text-xs text-red-500 ">{errors.gameType && errors.gameType.message}</p>
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <DropdownInput
                  {...field}
                  ref={field.ref}
                  label="Prize"
                  htmlFor="prize"
                  width="w-full"
                  defaultValue={'Trophy'}
                  options={[
                    {
                      value: '',
                      hidden: true,
                      disabled: true,
                      label: 'Select Prize'
                    },
                    { value: 'Trophy', disabled: false, label: 'Trophy' },
                    {
                      value: 'Belt',
                      disabled: true,
                      label: 'Belt - Comming soon'
                    }
                  ]}
                />
              );
            }}
            name="prize"
            rules={{
              required: {
                value: true,
                message: 'Please choose a prize'
              }
            }}
            defaultValue={'Trophy'}
          />
          <p className="text-xs text-red-500 ">{errors.prize && errors.prize.message}</p>
          <div className="pt-5">
            <MainButton title={'Gamify Me'} action={handleSubmit(submit)} type="submit" />
          </div>
        </form>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CreateGameForm;
