import { GREEN_CAR, RED_CAR, START_X, START_Y } from '../utils/constants';

const ASSETS = {
  red: RED_CAR,
  green: GREEN_CAR
};

export class Car {
  constructor(scene, path, main, color = 'red') {
    this.scene = scene;
    this.image = scene.add.follower(path, START_X, START_Y, ASSETS[color]).setOrigin(0.5, 0.5);
    if (!main) {
      this.image.alpha = 0.5;
    }
  }

  onUpdate = (tween) => {
    if (tween.totalProgress >= this.to) {
      this.image.pauseFollow();
    }
  };

  move(from, to, startDelay = 2000, rawDuration = 3000) {
    if (!to) {
      return;
    }
    const duration = rawDuration * (1 / (to - from));
    this.to = to;
    this.from = from;
    if (this.started) {
      setTimeout(() => {
        this.image.resumeFollow();
      }, startDelay);
      return;
    }
    this.image.startFollow({
      duration,
      positionOnPath: true,
      repeat: 0,
      startDelay,
      from,
      to: 1,
      rotateToPath: true,
      onUpdate: this.onUpdate
    });
    this.started = true;
  }
}
