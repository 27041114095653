import Phaser from 'phaser';
import raceTrack from '../../../../assets/games/race2/race-track.png';
import greenCar from '../../../../assets/games/race2/green-car.png';
import redCar from '../../../../assets/games/race2/red-car.png';
import { PATH, GREEN_CAR, RACE_TRACK, RED_CAR } from '../utils/constants';
import { Car } from '../components/Car';
import { calculateCompletion } from '../utils/score';

export class MainScene extends Phaser.Scene {
  preload() {
    this.load.image(RACE_TRACK, raceTrack);
    this.load.image(GREEN_CAR, greenCar);
    this.load.image(RED_CAR, redCar);
  }

  create() {
    this.path = new Phaser.Curves.Path(PATH);
    this.add.image(0, 0, RACE_TRACK).setOrigin(0, 0);
    this.text = this.add.text(10, 10, '', { fill: '#00ff00' });
    this.layer = this.add.layer();
  }

  updateParams = (params) => {
    if (!params.run) {
      return;
    }
    const prevParams = params.prev;
    const updatedPlayers = calculateCompletion(params.players, params.maxScore);
    const updatedPrevPlayers = prevParams
      ? calculateCompletion(prevParams.players, prevParams.maxScore)
      : null;
    if (!this.players) {
      this.players = updatedPlayers.reduce((res, { id, main, ...rest }) => {
        const car = new Car(this, this.path, main, main ? 'red' : 'green');
        const prevData = updatedPrevPlayers?.find(({ id: prevId }) => prevId === id);
        return {
          ...res,
          [id]: {
            id,
            car,
            main,
            prevCompletion: prevData?.completion,
            ...rest
          }
        };
      }, {});
      this.layer.add(Object.values(this.players).map(({ car }) => car.image));
    }
    updatedPlayers.forEach(({ id, completion, prevCompletion, main }) => {
      this.players[id]?.car.move(
        prevCompletion || this.players[id]?.prevCompletion || 0,
        completion
      );
      this.players[id] = {
        ...(this.players[id] || {}),
        prevCompletion: completion,
        main
      };
      this.layer.bringToTop(this.players[id].car.image);
    });
  };
}
