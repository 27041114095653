import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/ui/Sidebar';
import { getRoleHasTeachers } from '../../redux/modules/canvas/actions';

const DashboardLayout = () => {
  const { userData } = useSelector((state) => state.canvas);
  const { userLocal } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userLocal && userLocal.role === null && userData.id) {
      dispatch(
        getRoleHasTeachers({
          userId: userData.id,
          schoolUrl: userLocal.schoolUrl,
          accessToken: userLocal.accessToken
        })
      );
    }
  }, [userData.id]);
  return (
    <div className="flex flex-col h-screen">
      <Sidebar profilePicture={userData.avatar_url} />
    </div>
  );
};

export default DashboardLayout;
