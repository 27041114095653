import React from 'react'

const TextError = ({error}) => {

  if(!error) return null

  return (
    <p className="text-xs text-red-500 ">
      {error}
    </p>
  )
}

export default TextError