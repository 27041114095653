import { useEffect } from 'react';
import { isAuth } from '../redux/modules/auth/actions';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserLocalData } from '../redux/modules/user/actions';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(isAuth());
    if (user?.uid) {
      dispatch(fetchUserLocalData(user.uid));
    }
  }, [user]);

  return { user, isAuthenticated };
};
