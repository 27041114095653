import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignupForm from '../../components/forms/SignupForm';
import LogoPair from '../../components/ui/common/LogoPair';
import BlankLayout from '../../layouts/BlankLayout';

const Signup = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated]);
  return (
    <BlankLayout className="text-5xl">
      <div className="max-w-6xl mx-auto flex justify-center items-center flex-col p-4">
        <LogoPair />
        <div className="w-full justify-center items-center flex p-4 text-center">
          <h1 className="text-3xl font-bold">Signup your school to Gamify</h1>
        </div>
        <SignupForm />
      </div>
    </BlankLayout>
  );
};

export default Signup;
