import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Error from '../pages/Error';
import Home from '../pages/Home';
import Signup from '../pages/Signup';
import NewUser from '../pages/NewUser';
import Dashboard from '../pages/Dashboard';
import Signin from '../pages/Signin';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import SingleCourse from '../pages/SingleCourse';
import Notification from '../components/ui/Notifications/Notification';
import Courses from '../pages/Courses';
import Games from '../pages/Games';
import SingleGame from '../pages/SingleGame';
import PlayGame from '../pages/play/PlayGame';
import StudentSignin from '../pages/student/StudentSignin';
import StudentSignup from '../pages/student/StudentSignup';
import StudentProfile from '../pages/student/StudentProfile';
import RequireAuth from './RequireAuth';

const Router = () => {
  return (
    <BrowserRouter>
      <Notification />
      <Routes>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/signin" element={<Signin />}></Route>
        <Route path="/signin-s" element={<StudentSignin />}></Route>
        <Route path="/signup-s" element={<StudentSignup />}></Route>
        <Route path="/recover-password" element={<RecoverPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route element={<RequireAuth allowedRole={[null, undefined, 'Teacher']} />}>
          <Route path="/new-user" element={<NewUser />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/courses" element={<Courses />}></Route>
          <Route path="/course/:id" element={<SingleCourse />}></Route>
          <Route path="/games" element={<Games />}></Route>
          <Route path="/game/:id" element={<SingleGame />}></Route>

          {/* <Route path="/course/:id/quiz/:quizId" element={<SingleQuiz />}></Route> */}
        </Route>
        <Route element={<RequireAuth allowedRole={['Student']} />}>
          <Route path="/profile" element={<StudentProfile />}></Route>

          {/* <Route path="/course/:id/quiz/:quizId" element={<SingleQuiz />}></Route> */}
        </Route>
        <Route path="/game/play/:id/:room" element={<PlayGame />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
