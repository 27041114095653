import React from 'react';
import { useSearchParams } from 'react-router-dom';
import SigninForm from '../../../components/forms/SigninForm';
import LogoPair from '../../../components/ui/common/LogoPair';
import StudentLayout from '../../../layouts/StudentLayout';

const StudentSignin = () => {
  const [search] = useSearchParams();
  const redirectTo = search.get('redirectTo');
  return (
    <StudentLayout>
      <div className="max-w-6xl mx-auto flex justify-center items-center flex-col">
        <LogoPair />
        <div className="w-full justify-center items-center flex p-4 text-center">
          <h1 className="text-3xl font-bold">Signin to Gamify</h1>
        </div>
      </div>
      <SigninForm
        signupLink={`/signup-s?redirectTo=${redirectTo}`}
        recoveryLink={'/recover-password-s'}
        redirectLink={redirectTo}
      />
    </StudentLayout>
  );
};

export default StudentSignin;
