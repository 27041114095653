import { USER_LOADING, USER_ERROR, GET_USER_LOCAL_DATA, CONNECT_USER_TO_CANVAS } from './constants';
import { db } from '../../../config/firebase-config';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';

export function userError(data) {
  return {
    type: USER_ERROR,
    payload: data
  };
}

export function userLoading(data) {
  return {
    type: USER_LOADING,
    payload: data
  };
}

export function getUserLocalData(data) {
  return {
    type: GET_USER_LOCAL_DATA,
    payload: data
  };
}
export function connectToCanvasSuccess(data) {
  return {
    type: CONNECT_USER_TO_CANVAS,
    payload: data
  };
}

export const fetchUserLocalData = (id) => async (dispatch) => {
  dispatch(userLoading('Getting account data'));
  const unsub = onSnapshot(doc(db, 'users', id), (doc) => {
    if (!doc.data()) {
      dispatch(userError("We can't find your user"));
    }
    dispatch(getUserLocalData(doc.data()));
  });
  return () => unsub();
};

export const addTokens = (data) => async (dispatch) => {
  dispatch(userLoading('Updateing your token'));
  const ref = doc(db, 'users', data.id);

  try {
    await updateDoc(ref, {
      accessToken: data.accessToken,
      schoolUrl: data.schoolUrl
    });
    dispatch(connectToCanvasSuccess('Done'));
    if (data.callback) data.callback();
  } catch (error) {
    dispatch(userError('We could not connect'));
  }
};
