import React from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../../components/ui/Navbar';
import { signoutUser } from '../../redux/modules/auth/actions';
import clearAllStoreData from '../../redux/resetAction';

const NewUserLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOutcallback = () => {
    navigate('/');
    dispatch(clearAllStoreData());
  };
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <Outlet />
      <div
        onClick={() => {
          dispatch(signoutUser({ callback: signOutcallback }));
        }}>
        SignOut
      </div>
    </div>
  );
};

export default NewUserLayout;
