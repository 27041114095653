import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GameCard from '../../components/ui/cards/GameCard';
import GridList from '../../components/ui/lists/GridList';
import Tab from '../../components/ui/Tabs';
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce';
import { fetchActiveGames, fetchGames } from '../../redux/modules/games/actions';

const Games = () => {
  const dispatch = useDispatch();
  const { games } = useSelector((state) => state.games);
  const tabContent = useMemo(() => {
    const activeGames = [];
    const archivedGames = [];
    games.forEach((game) => {
      if (game.archived) {
        archivedGames.push(game);
      } else {
        activeGames.push(game);
      }
    });
    return [
      {
        title: 'Active Games',
        content: activeGames
      },
      {
        title: 'Archived Games',
        content: archivedGames
      }
    ];
  });

  useEffectOnlyOnce(() => {
    dispatch(fetchGames());
    dispatch(fetchActiveGames());
  });
  return (
    <div>
      {games.length > 0 && (
        <>
          <Tab>
            {tabContent.map((tab, idx) => (
              <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                <GridList data={tab.content} Component={GameCard} />
              </Tab.TabPane>
            ))}
          </Tab>
        </>
      )}
    </div>
  );
};

export default Games;
