import React from 'react';

const Input = React.forwardRef(
  ({
    Icon,
    width,
    defaultValue,
    placeholder,
    onChange,
    value,
    disabled,
    type,
    id,
    label,
    htmlFor,
    labelClassName = 'sr-only'
  }) => {
    return (
      <div
        className={`${
          width ? width : 'w-full'
        } flex justify-center  items-center py-2 px-7 outline-none focus:outline-none border-fade border-gameBlue border-2 rounded-3xl my-4`}>
        {Icon && Icon}
        {label && (
          <label htmlFor={htmlFor} className={labelClassName}>
            {label}
          </label>
        )}
        <input
          id={id}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          type={type}
          className={`flex flex-1 appearance-none ${
            width ? width : 'w-1/2'
          } py-3 ml-2 leading-tight outline-none border-0 focus:outline-none focus:shadow-none shadow-none p-0`}
        />
      </div>
    );
  }
);
Input.displayName = 'Input';
export default Input;
