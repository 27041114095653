import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendQuestionResponse } from '../../../redux/modules/games/actions';
import LeaderBoard from '../Leaderboard';
import Timer from '../Timer';

const QuizGame = ({
  question,
  currentQuestion,
  totalQuestions,
  roomId,
  playerId,
  playerScore,
  scores,
  playerDetails,
  setIsAnswerSelected
}) => {
  const { activePlayRoom } = useSelector((state) => state.games);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const dispatch = useDispatch();
  const [selectedAnswer, setSelectedAnswer] = useState({});

  const handleOnSelectAnswer = (answerId) => {
    const isCorrect = question.answers.find((answers) => answers.id === answerId).weight === 100;
    setIsAnswerSelected(true);
    setSelectedAnswer({
      answer: answerId,
      correct: isCorrect
    });
    sendResponse({
      answer: answerId,
      correct: isCorrect
    });
  };
  const sendResponse = (data) => {
    if (data.answer) {
      dispatch(
        sendQuestionResponse({
          currentQuestion: currentQuestion,
          roomId: roomId,
          playerId: playerId,
          answer: {
            answer: data.answer,
            correct: data.correct
          },
          currentQuestionStartTime: activePlayRoom.questionState.seconds,
          prevScore: playerScore
        })
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    setSelectedAnswer({});
    setIsAnswerSelected(false);
  }, [currentQuestion]);
  const renderAnswers = useMemo(() => {
    return (
      <div className="w-full flex flex-col justify-center items-center ">
        {question.answers?.map((answer) => {
          return (
            <button
              disabled={selectedAnswer.answer ? true : false}
              onClick={() => {
                handleOnSelectAnswer(answer.id);
              }}
              className={`w-full border-2 border-backgroundAccent ${
                answer.id === selectedAnswer.answer &&
                answer.weight === 100 &&
                'bg-green-400 text-white border-green-400'
              } ${
                answer.id === selectedAnswer.answer &&
                answer.weight !== 100 &&
                'bg-red-400 text-white border-red-400'
              } flex flex-col justify-center items-center my-4 rounded-xl p-4`}
              key={answer.id}>
              {answer.text}
            </button>
          );
        })}
      </div>
    );
  }, [question.answers, currentQuestion, selectedAnswer]);
  return (
    <div className="absolute left-0 top-0 w-full bg-white rounded-xl p-4 h-full">
      <div className="w-full pb-4 px-2">
        <p className="text-sm text-gray-400 font-bold py-2">
          Question {currentQuestion} of {totalQuestions}
        </p>
        <Timer
          numberOfQuestions={totalQuestions}
          roomId={roomId}
          setShowLeaderboard={setShowLeaderboard}
        />
        <h2
          className="text-2xl font-bold"
          dangerouslySetInnerHTML={{ __html: question.question_text }}
        />
      </div>
      {/* // FOR LEADERBOARD TESTING */}
      {showLeaderboard ? (
        <LeaderBoard scores={scores} playerDetails={playerDetails} />
      ) : (
        <div className="w-full p-2 rounded-xl flex justify-center items-center">
          {renderAnswers}
        </div>
      )}
    </div>
  );
};

export default QuizGame;
