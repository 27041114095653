import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const useTimer = ({ setTimeOut, startTime, secondsToCount }) => {
  const { activePlayRoom } = useSelector((state) => state.games);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    const date = new Date(startTime * 1000).getTime();

    setEndTime(new Date(date + secondsToCount * 1000).getTime());
  }, [startTime]);
  const currentTime = new Date().getTime();

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timer === 0) return setTimeOut(true);
    if (endTime && currentTime > endTime) {
      setTimer(0);
      return setTimeOut(true);
    }
    // const interval = setInterval(() => {
    //   setTimer(Math.floor((endTime - currentTime) / 1000));
    // }, 1000);
    // return () => clearInterval(interval);
    const animimationFrame = requestAnimationFrame(function animate() {
      let interval = (endTime - currentTime) / 1000;
      if (interval > secondsToCount) interval = secondsToCount;

      setTimer(interval);
      if (interval < secondsToCount) {
        requestAnimationFrame(animate);
      }
    });
    return () => cancelAnimationFrame(animimationFrame);
  }, [timer, endTime, currentTime]);

  useEffect(() => {
    const date = new Date(startTime * 1000).getTime();

    setEndTime(new Date(date + secondsToCount * 1000).getTime());
    setTimer(null);
    setTimeOut(false);
  }, [activePlayRoom.currentQuestion, endTime]);

  return Math.floor(timer);
};
export default useTimer;
