import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import gamifyLogo from '../../../../assets/images/logo-big.png';
import { setSelectedGame } from '../../../../redux/modules/games/actions';
const GameCard = ({ item }) => {
  const dispatch = useDispatch();

  return (
    <Link to={`/game/${item.gameId}`} onClick={() => dispatch(setSelectedGame(item))}>
      <div className="w-full rounded overflow-hidden">
        {item.coverImage ? (
          <img
            className={`w-full max-h-48 object-cover ${item.archived && 'grayscale'}`}
            src={item.coverImage}
            alt={item.name}
          />
        ) : (
          <img
            className={`w-full max-h-48 object-cover ${item.archived && 'grayscale'}`}
            src={gamifyLogo}
            alt={item.name}
          />
        )}

        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">
            {item.name} <span className="text-xs">{item.archived && '(Archived)'}</span>
          </div>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {item.questions.length} questions
          </span>
          <span className="inline-block bg-gameBlue rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
            {item.gameType}
          </span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {item.prize}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default GameCard;
