import { USER_LOADING, USER_ERROR, GET_USER_LOCAL_DATA, CONNECT_USER_TO_CANVAS } from './constants';

export const initialState = {
  userLocal: {},
  loading: '',
  error: ''
};

const userError = (state, action) => {
  const newState = { ...state };

  newState.loading = '';
  newState.error = action.payload;

  return newState;
};

const userLoading = (state, action) => {
  const newState = { ...state };

  newState.loading = action.payload;
  newState.error = '';
  return newState;
};

function userLocalData(state, action) {
  const newState = { ...state };
  newState.userLocal = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
}
function connectToCanvas(state) {
  const newState = { ...state };
  newState.loading = '';
  newState.error = '';
  return newState;
}

const handlers = {
  [USER_ERROR]: userError,
  [USER_LOADING]: userLoading,
  [GET_USER_LOCAL_DATA]: userLocalData,
  [CONNECT_USER_TO_CANVAS]: connectToCanvas
};

export default function user(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
