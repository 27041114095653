import Router from './router';
import { useAuth } from './hooks/useAuth';

import Loader from './components/ui/Loader';
function App() {
  // eslint-disable-next-line no-unused-vars
  useAuth();

  return (
    <div className="App">
      <Loader />
      <Router />
    </div>
  );
}

export default App;
