import React, { useCallback, Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
// import { CheckCircleIcon } from '@heroicons/react/outline';
import { XIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import { authErrorClear } from '../../../../redux/modules/auth/actions';
import { canvasErrorClear } from '../../../../redux/modules/canvas/actions';
import { useNavigate } from 'react-router-dom';

const Notification = React.memo(() => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error: authError } = useSelector((state) => state.auth);
  const { error: canvasError } = useSelector((state) => state.canvas);

  useEffect(() => {
    if (authError || canvasError) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [authError, canvasError]);
  const clearErrors = useCallback(() => {
    dispatch(authErrorClear());
    dispatch(canvasErrorClear());
    setShow(false);
  }, []);
  const invalidToken = () => {
    if (canvasError && canvasError === 'Invalid access token.') {
      return (
        <div
          onClick={() => {
            navigate('/new-user');
            clearErrors();
          }}
          className="mt-1  bg-red-700 p-2 rounded-xl text-center cursor-pointer">
          <p className="text-white">Add new token</p>
        </div>
      );
    }
    return <></>;
  };
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-red-50 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-700" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Error</p>
                    <p className="mt-1  text-red-700 font-bold">{authError || canvasError}</p>
                    {invalidToken()}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-red-50 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        clearErrors();
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
});
Notification.displayName = 'Notification';
export default Notification;
