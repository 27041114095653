export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_ERROR_CLEAR = 'AUTH_ERROR_CLEAR';
export const AUTH_LOADING_STOP = 'AUTH_LOADING_STOP';
export const IS_AUTH = 'IS_AUTH';
export const GET_USER_DATA = 'GET_USER_DATA';
