import {
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_SIGN_UP,
  AUTH_FORGOT_PASSWORD,
  AUTH_ERROR_CLEAR,
  AUTH_LOADING_STOP,
  IS_AUTH
} from './constants';

export const initialState = {
  user: {},
  hasToken: true,
  isAuthenticated: false,
  loading: '',
  error: '',
  forgotPassword: false
};

const authError = (state, action) => {
  const newState = { ...state };

  newState.loading = '';
  newState.error = action.payload;

  return newState;
};

const authLoading = (state, aciton) => {
  const newState = { ...state };

  newState.loading = aciton.payload;
  newState.error = '';
  return newState;
};
const authLoadingStop = (state) => {
  const newState = { ...state };

  newState.loading = '';
  return newState;
};

function signin(state, action) {
  const newState = { ...state };

  newState.user = action.payload;
  newState.loading = '';
  newState.isAuthenticated = true;
  newState.error = '';

  return newState;
}
function signup(state, action) {
  const newState = { ...state };
  newState.isAuthenticated = true;
  newState.user = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
}
function forgotPassword(state) {
  const newState = { ...state };

  newState.forgotPassword = true;
  newState.error = '';
  return newState;
}

function signout(state) {
  const newState = { ...state };

  newState.user = {};
  newState.userLocal = {};
  newState.loading = '';
  newState.isAuthenticated = false;
  newState.error = '';
  return newState;
}

function errorClear(state) {
  const newState = { ...state };
  newState.error = '';
  return newState;
}
function isAuthenticated(state, action) {
  const newState = { ...state };
  newState.isAuthenticated = action.payload ? true : false;
  newState.user = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
}

function success(state) {
  const newState = { ...state };
  newState.loading = '';
  newState.error = '';
  return newState;
}

const handlers = {
  [AUTH_SIGN_IN]: signin,
  [AUTH_SIGN_UP]: signup,
  [AUTH_LOADING]: authLoading,
  [AUTH_ERROR]: authError,
  [AUTH_SIGN_OUT]: signout,
  [AUTH_FORGOT_PASSWORD]: forgotPassword,
  [AUTH_ERROR_CLEAR]: errorClear,
  [AUTH_LOADING_STOP]: authLoadingStop,
  [IS_AUTH]: isAuthenticated,
  [AUTH_FORGOT_PASSWORD]: success
};

export default function loginEndUser(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
