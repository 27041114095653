export const WIDTH = 900;
export const HEIGHT = 924;
export const ROOT_ID = 'race-game-root';

export const START_X = 310;
export const START_Y = 78;
export const START_ANGLE = 90;

// assets
export const RACE_TRACK = 'race-track';
export const GREEN_CAR = 'greed-car';
export const RED_CAR = 'red-car';

export const PATH = {
  type: 'Path',
  x: 296.83329,
  y: 66.011279,
  autoClose: false,
  curves: [
    { type: 'LineCurve', points: [296.83329, 66.011279, 410.93367, 62.955629] },
    {
      type: 'CubicBezierCurve',
      points: [
        410.93367, 62.955629, 576.16151, 58.530746, 527.02613, 221.40866, 593.89389, 217.40284
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        593.89389, 217.40284, 636.47873, 214.85175, 730.36137, 3.1438636, 810.22871, 97.400029
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        810.22871, 97.400029, 856.71421, 152.26034, 816.54918, 211.35628, 781.88905,
        240.09053999999998
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        781.88905, 240.09053999999998, 707.49535, 301.76507, 667.57968, 314.92435, 630.62605,
        370.22750999999994
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        630.62605, 370.22750999999994, 605.5844099999999, 407.70356999999996, 590.81007,
        453.66763999999995, 588.3738, 498.67427
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        588.3738, 498.67427, 584.71566, 566.25231, 627.23714, 629.66788, 630.62605, 697.25989
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        630.62605, 697.25989, 633.20854, 748.76986, 655.27135, 820.4989, 613.72512,
        851.0580500000001
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        613.72512, 851.0580500000001, 573.49615, 880.6484300000001, 509.46901999999994,
        848.0886100000001, 466.68728999999996, 822.32653
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        466.68728999999996, 822.32653, 353.43948, 754.1315, 201.9177, 524.22668, 238.45125,
        450.14369
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        238.45125, 450.14369, 274.76537, 376.50569, 500.67905, 427.17728, 497.95401, 332.20044
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        497.95401, 332.20044, 496.3171, 275.14948, 399.74226, 285.02729, 338.26958, 270.40805
      ]
    },
    {
      type: 'CubicBezierCurve',
      points: [338.26958, 270.40805, 262.37267, 252.35852, 62.847406, 267.253, 60.008806, 168.51755]
    },
    {
      type: 'CubicBezierCurve',
      points: [
        60.008806, 168.51755, 57.536816, 82.534099, 129.62201, 71.002649, 296.83329, 66.011279
      ]
    },
    { type: 'LineCurve', points: [296.83329, 66.011279, 296.83329, 66.011279] }
  ]
};
