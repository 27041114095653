import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-small.png';

const navigation = [
  { name: 'Games', href: '#' },
  { name: 'Quizzes', href: '#' }
];
const Navbar = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const {
    userLocal: { role }
  } = useSelector((state) => state.user);
  return (
    <header className="bg-white">
      <nav className=" mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-accentYellow lg:border-none">
          <div className="flex items-center justify-between">
            <Link to="/">
              <span className="sr-only">Workflow</span>
              <img className="h-10 w-auto" src={logo} alt="Gamify" />
            </Link>
          </div>
          <div className="ml-10 space-x-4 flex items-center">
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className="text-base font-medium text-gameBlue hover:text-opacity-75">
                  {link.name}
                </a>
              ))}
            </div>
            {isAuthenticated ? (
              role === 'Student' ? (
                <>
                  <Link
                    to={'/profile'}
                    className="inline-block bg-accentYellow py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75 text-center">
                    Profile
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={'/dashboard'}
                    className="inline-block bg-accentYellow py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75 text-center">
                    Dashboard
                  </Link>
                </>
              )
            ) : (
              <>
                <Link
                  to="/signin"
                  className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-gameBlue hover:text-opacity-75 text-center">
                  Sign in
                </Link>
                <Link
                  to="/signup"
                  className="inline-block bg-accentYellow py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75 text-center">
                  Sign up
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="text-base font-medium text-gameBlue hover:text-opacity-75">
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
