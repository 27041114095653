import {
  GAME_LOADING,
  GAME_ERROR,
  CREATE_NEW_GAME,
  GET_GAMES,
  SET_SELECTED_GAME,
  NEW_GAME_ROOM,
  GET_ACTIVE_ROOMS,
  ARCHIVE_GAME,
  GET_PLAYING_GAME,
  CURRENT_QUESTION_UPDATE,
  GET_PLAY_ROOM,
  GAME_SUCCESS,
  SHOW_GAME_SCREEN,
  GET_PLAYER_DETAILS
} from './constants';

export const initialState = {
  games: [],
  selectedGame: null,
  activeGames: {},
  loading: '',
  error: '',
  playingGame: {},
  activePlayRoom: {},
  playerDetails: {},
  showGamesScreen: false,
  currentQuestion: 0
};

const gameError = (state, action) => {
  const newState = { ...state };

  newState.loading = '';
  newState.error = action.payload;

  return newState;
};

const gameLoading = (state, action) => {
  const newState = { ...state };

  newState.loading = action.payload;
  newState.error = '';
  return newState;
};
const createNewGame = (state, action) => {
  const newState = { ...state };
  newState.selectedGame = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const getGames = (state, action) => {
  const newState = { ...state };
  newState.games = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const setSelectedGame = (state, action) => {
  const newState = { ...state };
  newState.selectedGame = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const newGameroom = (state, action) => {
  const newState = { ...state };
  if (!(Object.keys(action.payload) in newState.activeGames)) {
    newState.activeGames = { ...newState.activeGames, ...action.payload };
  }
  // newState.activeGames = { ...newState.activeGames, ...action.payload };
  newState.loading = '';
  newState.error = '';
  return newState;
};
const getActiveRooms = (state, action) => {
  const newState = { ...state };

  newState.activeGames = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const archiveGame = (state, action) => {
  const newState = { ...state };
  newState.selectedGame = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const playingGame = (state, action) => {
  const newState = { ...state };
  newState.playingGame = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const currentQuestionHandler = (state, action) => {
  const newState = { ...state };
  newState.currentQuestion = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const getActivePlayRoom = (state, action) => {
  const newState = { ...state };
  newState.activePlayRoom = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
};
const success = (state) => {
  const newState = { ...state };
  newState.loading = '';
  newState.error = '';
  return newState;
};
const setGameScreen = (state, action) => {
  const newState = { ...state };
  newState.showGamesScreen = action.payload;
  return newState;
};
const getPlayerDetails = (state, action) => {
  const newState = { ...state };
  newState.loading = '';
  newState.error = '';
  newState.playerDetails = action.payload;
  return newState;
};

const handlers = {
  [GAME_LOADING]: gameLoading,
  [GAME_ERROR]: gameError,
  [CREATE_NEW_GAME]: createNewGame,
  [GET_GAMES]: getGames,
  [SET_SELECTED_GAME]: setSelectedGame,
  [NEW_GAME_ROOM]: newGameroom,
  [GET_ACTIVE_ROOMS]: getActiveRooms,
  [ARCHIVE_GAME]: archiveGame,
  [GET_PLAYING_GAME]: playingGame,
  [CURRENT_QUESTION_UPDATE]: currentQuestionHandler,
  [GET_PLAY_ROOM]: getActivePlayRoom,
  [GAME_SUCCESS]: success,
  [SHOW_GAME_SCREEN]: setGameScreen,
  [GET_PLAYER_DETAILS]: getPlayerDetails
};

export default function games(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
