import React from 'react';
import { useSearchParams } from 'react-router-dom';
import SignupForm from '../../../components/forms/SignupForm';
import LogoPair from '../../../components/ui/common/LogoPair';
import StudentLayout from '../../../layouts/StudentLayout';

const StudentSignup = () => {
  const [search] = useSearchParams();
  return (
    <StudentLayout>
      <div className="max-w-6xl mx-auto flex justify-center items-center flex-col">
        <LogoPair />
        <div className="w-full justify-center items-center flex p-4 text-center">
          <h1 className="text-3xl font-bold">Signup your school to Gamify</h1>
        </div>
      </div>
      <SignupForm signinLink={'/signin-s'} redirectLink={search.get('redirectTo')} student={true} />
    </StudentLayout>
  );
};

export default StudentSignup;
