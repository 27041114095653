import {
  GET_COURSES,
  CANVAS_LOADING,
  CANVAS_ERROR,
  GET_USER_CANVAS_DATA,
  GET_QUIZZES,
  CANVAS_ERROR_CLEAR,
  GET_QUIZ_QUESTIONS,
  GET_ROLE
} from './constants';
import axios from 'axios';
import { auth, db } from '../../../config/firebase-config';
import { doc, updateDoc } from 'firebase/firestore';
export function canvasError(data) {
  return {
    type: CANVAS_ERROR,
    payload: data
  };
}

export function canvasLoading(data) {
  return {
    type: CANVAS_LOADING,
    payload: data
  };
}
export function fetchCoursesSuccess(data) {
  return {
    type: GET_COURSES,
    payload: data
  };
}
export function fetchUsersCanvasDataSuccess(data) {
  return {
    type: GET_USER_CANVAS_DATA,
    payload: data
  };
}
export function fetchQuizzesSuccess(data) {
  return {
    type: GET_QUIZZES,
    payload: data
  };
}
export function fetchQuizQuestionsSuccess(data) {
  return {
    type: GET_QUIZ_QUESTIONS,
    payload: data
  };
}
export function clearErrors() {
  return {
    type: CANVAS_ERROR_CLEAR
  };
}
export function getUserRole(data) {
  return {
    type: GET_ROLE,
    payload: data
  };
}

export const fetchCourses =
  ({ schoolUrl, accessToken }) =>
  async (dispatch) => {
    dispatch(canvasLoading('Fetching quizess...'));

    axios
      .get(
        `https://us-central1-gamify-ed.cloudfunctions.net/getCourses?accessToken=${accessToken}&schoolUrl=${schoolUrl}`,
        {
          withCredentials: false
        }
      )
      .then((response) => {
        dispatch(fetchCoursesSuccess(response.data));
      })
      .catch((err) => {
        if (err.response) {
          if (!err.response.data.errors) {
            dispatch(canvasError('Invalid access token.'));
          }
          dispatch(canvasError(err.response.data.errors[0].message));
        } else {
          console.log(err);
        }
      });
  };
export const fetchUserCanvasData =
  ({ schoolUrl, accessToken }) =>
  async (dispatch) => {
    dispatch(canvasLoading('Getting your user data'));

    axios
      .get(
        `https://us-central1-gamify-ed.cloudfunctions.net/getUserData?accessToken=${accessToken}&schoolUrl=${schoolUrl}`,
        {
          withCredentials: false
        }
      )
      .then((response) => {
        dispatch(fetchUsersCanvasDataSuccess(response.data));
      })
      .catch((err) => {
        if (err.response) {
          if (!err.response.data.errors) {
            dispatch(canvasError('Invalid access token.'));
          }
          dispatch(canvasError(err.response.data.errors[0].message));
        } else {
          console.log(err);
        }
      });
  };

export const fetchQuizzes =
  ({ schoolUrl, accessToken, courseId }) =>
  async (dispatch) => {
    dispatch(canvasLoading('Getting your user data'));

    axios
      .get(
        `https://us-central1-gamify-ed.cloudfunctions.net/getQuizzes?accessToken=${accessToken}&schoolUrl=${schoolUrl}&courseId=${courseId}`,
        {
          withCredentials: false
        }
      )
      .then((response) => {
        dispatch(fetchQuizzesSuccess({ [courseId]: response.data }));
      })
      .catch((err) => {
        if (err.response) {
          if (!err.response.data.errors) {
            dispatch(canvasError('Invalid access token.'));
          }
          dispatch(canvasError(err.response.data.errors[0].message));
        } else {
          console.log(err);
        }
      });
  };
export const fetchQuizQuestions =
  ({ schoolUrl, accessToken, courseId, quizId, numberOfQuestions }) =>
  async (dispatch) => {
    dispatch(canvasLoading('Quiz questions'));

    axios
      .get(
        `https://us-central1-gamify-ed.cloudfunctions.net/getQuizQuestions?accessToken=${accessToken}&schoolUrl=${schoolUrl}&courseId=${courseId}&quizId=${quizId}&numberOfQuestions=${numberOfQuestions}`,
        {
          withCredentials: false
        }
      )
      .then((response) => {
        dispatch(fetchQuizQuestionsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response) {
          if (!err.response.data.errors) {
            dispatch(canvasError('Invalid access token.'));
          }
          dispatch(canvasError(err.response.data.errors[0].message));
        } else {
          console.log(err);
        }
      });
  };
export const canvasErrorClear = () => async (dispatch) => {
  dispatch(clearErrors());
};
export const getRoleHasTeachers =
  ({ schoolUrl, accessToken, userId }) =>
  async (dispatch) => {
    dispatch(canvasLoading('Getting user role'));
    try {
      const response = await axios.get(
        `https://us-central1-gamify-ed.cloudfunctions.net/getRole?accessToken=${accessToken}&schoolUrl=${schoolUrl}&userId=${userId}`,
        {
          withCredentials: false
        }
      );
      const isStudent = response.data.find((enrollment) => enrollment.role.includes('Student'));
      const isTeacher = response.data.find((enrollment) => enrollment.role.includes('Teacher'));
      const locaUserId = auth.currentUser.uid;
      const ref = doc(db, 'users', locaUserId);
      if (isStudent) {
        await updateDoc(ref, {
          role: 'Student'
        });
        dispatch(getUserRole('Done'));
      }
      if (isTeacher) {
        await updateDoc(ref, {
          role: 'Teacher'
        });
        dispatch(getUserRole('Done'));
      }
    } catch (err) {
      if (err.response) {
        if (!err.response.data.errors) {
          dispatch(canvasError('Invalid access token.'));
        }
        dispatch(canvasError(err.response.data.errors[0].message));
      } else {
        dispatch(canvasError('We could get your role'));
      }
    }
  };
