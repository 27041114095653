export const CREATE_NEW_GAME = 'CREATE_NEW_GAME';
export const GAME_ERROR = 'GAME_ERROR';
export const GAME_LOADING = 'GAME_LOADING';
export const GET_GAMES = 'GET_GAMES';
export const SET_SELECTED_GAME = 'SET_SELECTED_GAME';
export const NEW_GAME_ROOM = 'NEW_GAME_ROOM';
export const GET_ACTIVE_ROOMS = 'GET_ACTIVE_ROOMS';
export const ARCHIVE_GAME = 'ARCHIVE_GAME';
export const GET_PLAYING_GAME = 'GET_PLAYING_GAME';
export const CURRENT_QUESTION_UPDATE = 'CURRENT_QUESTION_UPDATE';
export const GET_PLAY_ROOM = 'GET_PLAY_ROOM';
export const GAME_SUCCESS = 'GAME_SUCCESS';
export const SHOW_GAME_SCREEN = 'SHOW_GAME_SCREEN';
export const GET_PLAYER_DETAILS = 'GET_PLAYER_DETAILS';
