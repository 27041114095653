import React from 'react';
// import { useParams } from 'react-router-dom';
import RestePasswordEmailForm from '../../components/forms/RestePasswordEmailForm';
import LogoPair from '../../components/ui/common/LogoPair';
import BlankLayout from '../../layouts/BlankLayout';

const RecoverPassword = () => {
  return (
    <BlankLayout className="text-5xl">
      <div className="max-w-6xl mx-auto flex justify-center items-center flex-col p-4">
        <LogoPair />
        <div className="w-full justify-center items-center flex p-4 text-center">
          <h1 className="text-3xl font-bold">Recover password on Gamify</h1>
        </div>
        <RestePasswordEmailForm />
      </div>
    </BlankLayout>
  );
};

export default RecoverPassword;
