import { SHOW_MODAL, HIDE_MODAL } from './constants';

export const initialState = {
  modal: {
    visible: false,
    data: {}
  }
};

function showModal(state, action) {
  const newState = { ...state };
  newState.modal = { visible: true, data: action.payload };
  return newState;
}
function hideModal(state) {
  const newState = { ...state };
  newState.modal = { visible: false, data: {} };
  return newState;
}
const handlers = {
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal
};

export default function ui(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
