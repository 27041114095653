import { useDispatch } from 'react-redux';
import { showModal } from '../../../../redux/modules/ui/actions';
const QuizCard = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() =>
        item.published
          ? dispatch(
              showModal({ id: item.id, numberOfQuestions: item.question_count, title: item.title })
            )
          : {}
      }
      className={`${item.published ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
      <div className="max-w-sm rounded overflow-hidden ">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{item.title}</div>
        </div>

        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-backgroundAccent rounded-full px-3 py-1 text-sm font-semibold text-gameBlue mr-2 mb-2">
            {item.question_count} questions found in quiz
          </span>
        </div>

        <div className="px-6 pt-4 pb-2">
          {item.published ? (
            <span className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-green-700 mr-2 mb-2">
              Published
            </span>
          ) : (
            <span className="inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-red-700 mr-2 mb-2">
              Not Published
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
