import React, { useMemo } from 'react';

const DropdownInput = React.forwardRef(
  ({ options, width, value, disabled, defaultValue, id, label, htmlFor, ...props }, ref) => {
    const renderOptions = useMemo(() => {
      return (
        options?.length > 0 &&
        options.map((option, i) => {
          return (
            <option value={option.value} disabled={option.disabled} hidden={option.hidden} key={i}>
              {option.label}
            </option>
          );
        })
      );
    }, [options]);
    return (
      <>
        <label htmlFor={htmlFor} className="sr-only">
          {label}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2 w-full">
          <select
            ref={ref}
            {...props}
            id={id}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            autoComplete="country-name"
            className={`${
              width ? width : 'w-full'
            } flex justify-center py-2  items-center  outline-none focus:outline-none border-fade border-gameBlue border-2 rounded-3xl my-4`}>
            {renderOptions}
          </select>
        </div>
      </>
    );
  }
);
DropdownInput.displayName = 'DropdownInput';
export default DropdownInput;
