export const generatePinCode = () => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';

    while (code.length < 4) {
        const randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));

        if (code.indexOf(randomCharacter) === -1) {
            code += randomCharacter;
        }
    }

    return code;
}




