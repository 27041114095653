import { combineReducers } from 'redux';
import auth, { initialState as authState } from './modules/auth/reducer';
import user, { initialState as userState } from './modules/user/reducer';
import canvas, { initialState as canvasState } from './modules/canvas/reducer';
import ui, { initialState as uiState } from './modules/ui/reducer';
import games, { initialState as gamesState } from './modules/games/reducer';

const combinedReducers = combineReducers({
  auth,
  user,
  canvas,
  ui,
  games
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'CLEAR_ALL_REDUCERS_DATA':
      return {
        auth: { ...authState },
        user: { ...userState },
        canvas: { ...canvasState },
        ui: { ...uiState },
        games: { ...gamesState }
      };
    default:
      return combinedReducers(state, action);
  }
};

export default rootReducer;
