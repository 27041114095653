import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainButton from '../../components/ui/buttons/MainButton';
import GridList from '../../components/ui/lists/GridList';
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce';
import CourseCard from '../../components/ui/cards/CourseCard';
import { fetchCourses, fetchUserCanvasData } from '../../redux/modules/canvas/actions';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [noToken, setNoToken] = useState(false);
  const { userLocal } = useSelector((state) => state.user);

  const { courses, userData } = useSelector((state) => state.canvas);
  const getData = () => {
    dispatch(
      fetchUserCanvasData({
        accessToken: userLocal.accessToken,
        schoolUrl: userLocal.schoolUrl
      })
    );
    dispatch(
      fetchCourses({
        accessToken: userLocal.accessToken,
        schoolUrl: userLocal.schoolUrl
      })
    );
  };
  useEffectOnlyOnce(() => {
    if (userLocal?.accessToken && userLocal?.schoolUrl) {
      getData();
    }
  });
  const changeNoToken = useCallback(() => {
    setNoToken(true);
  }, []);
  useEffect(() => {
    if (!userLocal?.accessToken || !userLocal.schoolUrl) {
      changeNoToken();
    }
  }, [userLocal]);

  if (noToken) {
    return (
      <div className="flex flex-1 justify-center items-center w-full">
        <div className="w-1/2">
          <MainButton title="Please add your access token" action={() => navigate('/new-user')} />
        </div>
      </div>
    );
  }
  if (noToken) {
    return (
      <div className="flex flex-1 justify-center items-center w-full">
        <div className="w-1/2">
          <MainButton title="Please add your access token" action={() => navigate('/new-user')} />
        </div>
      </div>
    );
  }

  return (
    <div>
      Dashboard
      {userData.name}
      {courses.length > 0 && <GridList data={courses} Component={CourseCard} />}
    </div>
  );
};

export default Dashboard;
