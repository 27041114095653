import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchQuizzes } from '../../../../redux/modules/canvas/actions';
import { format } from 'date-fns';
const CourseCard = React.memo(({ item }) => {
  const { userLocal } = useSelector((state) => state.user);
  const { quizzes } = useSelector((state) => state.canvas);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchQuizzes({
        accessToken: userLocal.accessToken,
        schoolUrl: userLocal.schoolUrl,
        courseId: item.id
      })
    );
  }, []);
  return (
    <Link to={`/course/${item.id}`}>
      <div className="max-w-sm rounded overflow-hidden">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{item.name}</div>
        </div>
        {quizzes && quizzes[item.id] && (
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-backgroundAccent rounded-full px-3 py-1 text-sm font-semibold text-gameBlue mr-2 mb-2">
              {quizzes[item.id].length} quizzes found in course
            </span>
          </div>
        )}
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {format(new Date(item.created_at), 'MM/dd/yyyy')}
          </span>
        </div>
      </div>
    </Link>
  );
});
CourseCard.displayName = 'CourseCard';
export default CourseCard;
