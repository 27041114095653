const MAX_PER_QUESTION = 10;

export const getCurrentMaxScore = (amountOfQuestions, scores, amountOfAnsweredQuestions) => {
  if (typeof amountOfQuestions !== 'number') {
    return null;
  }
  if (typeof amountOfAnsweredQuestions !== 'number') {
    return null;
  }
  if (!Array.isArray(scores) || (scores.length > 0 && typeof scores[0] !== 'number')) {
    return null;
  }
  const maxScore = MAX_PER_QUESTION * amountOfQuestions;
  const leadingScore = scores.sort((a, b) => b - a)[0] || 0;
  const missingScore = amountOfAnsweredQuestions * MAX_PER_QUESTION - leadingScore;
  return maxScore - missingScore;
};
