import React from 'react';

const StudentLayout = ({ children }) => {
  return (
    <div className="flex flex-1 bg-gameBlue h-screen flex-col items-center p-4">
      <div className="w-full sm:w-1/2 bg-white rounded-xl m-4 p-4 h-full relative">{children}</div>
    </div>
  );
};

export default StudentLayout;
