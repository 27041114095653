import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RestePasswordForm from '../../components/forms/RestePasswordForm';

// import RestePasswordEmailForm from '../../components/forms/RestePasswordEmailForm';
import LogoPair from '../../components/ui/common/LogoPair';
// import useQuery from '../../hooks/useQuery';
import BlankLayout from '../../layouts/BlankLayout';

const ResetPassword = () => {
  let [searchParams] = useSearchParams();
  let [code] = useState(searchParams.get('oobCode'));

  return (
    <BlankLayout className="text-5xl">
      <div className="max-w-6xl mx-auto flex justify-center items-center flex-col p-4">
        <LogoPair />
        <div className="w-full justify-center items-center flex p-4 text-center">
          <h1 className="text-3xl font-bold">Reset Gamify account password</h1>
        </div>
        <RestePasswordForm code={code} />
      </div>
    </BlankLayout>
  );
};

export default ResetPassword;
