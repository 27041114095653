import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import useTimer from '../../../hooks/useTImer';
import {
  endGame,
  incrementQuestionCount,
  showGamesScreen
} from '../../../redux/modules/games/actions';

const Timer = ({ numberOfQuestions, roomId }) => {
  const CIRCLE_LENGTH = 2 * Math.PI * 45; /*  2πr = 2 * π * 45 = 282,6 */

  const [timeOut, setTimeOut] = useState(false);
  const [secondsToCount] = useState(5);
  const dispatch = useDispatch();
  const { activePlayRoom } = useSelector((state) => state.games);

  let timer = useTimer({
    startTime: activePlayRoom.questionState?.seconds,
    questionNumber: numberOfQuestions,
    secondsToCount: secondsToCount,
    setTimeOut: setTimeOut
  });
  const nextQuestionCallback = () => {
    dispatch(
      incrementQuestionCount({
        roomId: roomId,
        currentQuestion: activePlayRoom.currentQuestion
      })
    );
  };
  const endGameCallback = () => {
    dispatch(endGame({ roomId: roomId }));
  };

  // // FOR LEADERBOARD TESTING
  // useEffect(() => {
  //   if (timeOut) setShowLeaderboard(true);
  // }, [timeOut]);
  useEffect(() => {
    const delay = setTimeout(() => {
      if (activePlayRoom.currentQuestion < numberOfQuestions - 1 && timeOut) {
        // FOR LEADERBOARD TESTING
        // setShowLeaderboard(false);
        // nextQuestionCallback();
        //  ===>
        dispatch(showGamesScreen({ callback: nextQuestionCallback }));
      } else if (activePlayRoom.currentQuestion === numberOfQuestions - 1 && timeOut) {
        // dispatch(endGame({ roomId: roomId }));
        dispatch(showGamesScreen({ callback: endGameCallback }));
      }
      //3 sec FOR LEADERBOARD TESTING
    }, 3000);

    return () => clearTimeout(delay);
  }, [timeOut, activePlayRoom.currentQuestion, numberOfQuestions]);
  const calculateRemainingTimeCircleLength = () => {
    return (timer / secondsToCount) * CIRCLE_LENGTH;
  };

  const calculateRemainingTimeCircleColor = (left) => {
    return left > secondsToCount / 2
      ? 'SeaGreen'
      : left > secondsToCount / 4
      ? 'GoldenRod'
      : 'FireBrick';
  };
  return (
    <div className="w-full flex justify-center my-2">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="w-24 h-24 relative rounded-full shadow-lg">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g
            style={{
              fill: 'none',
              stroke: 'none'
            }}>
            <circle
              cx="50"
              cy="50"
              r="45"
              style={{
                strokeWidth: '5px',
                stroke: 'Silver'
              }}
            />
            <path
              strokeDasharray={`${calculateRemainingTimeCircleLength()} ${CIRCLE_LENGTH}`}
              d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
              style={{
                strokeWidth: '9px',
                strokeLinecap: 'butt',
                transform: 'rotate(90deg)',
                transformOrigin: 'center',
                transition: '1s linear all',
                stroke: calculateRemainingTimeCircleColor(timer)
              }}
            />
          </g>
        </svg>
        <div className="absolute top-0 w-24 h-24 flex justify-center items-center text-3xl font-bold">
          {timer}
        </div>
      </motion.div>
    </div>
  );
};

export default Timer;
