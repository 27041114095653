import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { ROOT_ID } from './utils/constants';
import { GameManager } from './GameManager';

const Root = React.memo(() => {
  return <div className="relative" id={ROOT_ID} />;
});
Root.displayName = 'Root';

export const RaceGame = (props) => {
  const gameManager = useRef();

  useLayoutEffect(() => {
    gameManager.current = new GameManager(props);
  }, []);

  useEffect(() => {
    gameManager.current.updateParams(props);
  }, [props]);

  return <Root />;
};
RaceGame.displayName = 'RaceGame';
