import React, { useState } from 'react';
import MainButton from '../../components/ui/buttons/MainButton';
import LogoPair from '../../components/ui/common/LogoPair';
import BlankLayout from '../../layouts/BlankLayout';

const Home = () => {
  const [welcoming, setWelcoming] = useState(true);
  return (
    <BlankLayout className="text-5xl">
      <div className="max-w-6xl mx-auto flex justify-center items-center flex-col flex-grow p-4">
        {welcoming ? (
          <>
            <LogoPair />
            <div className="w-full justify-center items-center flex p-4 text-center">
              <h1 className="text-5xl font-bold">Welcome to Gamify</h1>
            </div>
            <MainButton
              title="Go"
              width="w-1/2"
              action={() => setWelcoming(false)}
              backgroundColor="bg-backgroundAccent"
              textColor="text-gameBlue"
            />
          </>
        ) : (
          <>
            <LogoPair />
            <div className="w-full justify-center items-center flex p-4 text-center">
              <h2 className="text-xl">
                Welcome to Gamify - the fast and easy way to Gamify your quizzes
              </h2>
            </div>
            <ol className="list-decimal flex flex-col justify-center p-4">
              <li className="text-2xl font-bold p-2">Create your Gamify account</li>
              <li className="text-2xl font-bold p-2">Login to Canvas</li>
              <li className="text-2xl font-bold p-2">Link to your account</li>
              <li className="text-2xl font-bold p-2">Choose a Quiz to gamify</li>
              <li className="text-2xl font-bold p-2">Decide your options</li>
              <li className="text-2xl font-bold p-2">Share the game and start</li>
            </ol>
            <MainButton
              title="Get started"
              width="w-1/2"
              link={'/signup'}
              backgroundColor="bg-backgroundAccent"
              textColor="text-gameBlue"
            />
          </>
        )}
      </div>
    </BlankLayout>
  );
};

export default Home;
