import React, { useState } from 'react';
import { ClipboardIcon as ClipboardIconSolid } from '@heroicons/react/solid';
import { ClipboardIcon } from '@heroicons/react/outline';
const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="flex justify-start items-center">
        <div
          className="border-2 border-gray-400 bg-gray-300 overflow-x-scroll py-4 px-2 rounded-xl "
          style={{ fontSize: '8px' }}>
          {copyText}
        </div>
        <button onClick={handleCopyClick}>
          <span>
            {isCopied ? (
              <ClipboardIconSolid className="h-5 w-5 text-gameBlue" />
            ) : (
              <ClipboardIcon className="h-5 w-5 text-gameBlue" />
            )}
          </span>
        </button>
      </div>
      {/* <input type="text" value={} readOnly /> */}
      {/* Bind our handler function to the onClick button property */}
    </>
  );
};

export default ClipboardCopy;
