import {
  GAME_LOADING,
  GAME_ERROR,
  CREATE_NEW_GAME,
  GET_GAMES,
  SET_SELECTED_GAME,
  NEW_GAME_ROOM,
  GET_ACTIVE_ROOMS,
  ARCHIVE_GAME,
  GET_PLAYING_GAME,
  CURRENT_QUESTION_UPDATE,
  GET_PLAY_ROOM,
  GAME_SUCCESS,
  SHOW_GAME_SCREEN,
  GET_PLAYER_DETAILS
} from './constants';
import { db, storage, auth } from '../../../config/firebase-config';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  onSnapshot,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getRandomColor } from './utils';
import { generatePinCode } from '../../../utils/pinCode';

export function gameError(data) {
  return {
    type: GAME_ERROR,
    payload: data
  };
}

export function gameLoading(data) {
  return {
    type: GAME_LOADING,
    payload: data
  };
}
export function createNewGameSuccess(data) {
  return {
    type: CREATE_NEW_GAME,
    payload: data
  };
}
export function newGameroomSuccess(data) {
  return {
    type: NEW_GAME_ROOM,
    payload: data
  };
}
export function fetchGamesSuccess(data) {
  return {
    type: GET_GAMES,
    payload: data
  };
}
export function fetchActiveGamesSuccess(data) {
  return {
    type: GET_ACTIVE_ROOMS,
    payload: data
  };
}
export function archiveSuccess(data) {
  return {
    type: ARCHIVE_GAME,
    payload: data
  };
}
export function fetchPlayingGameSuccess(data) {
  return {
    type: GET_PLAYING_GAME,
    payload: data
  };
}
export function fetchPlayRoomSuccess(data) {
  return {
    type: GET_PLAY_ROOM,
    payload: data
  };
}
export function success() {
  return {
    type: GAME_SUCCESS
  };
}
export function handleGameScreenSuccess(data) {
  return {
    type: SHOW_GAME_SCREEN,
    payload: data
  };
}
export function getPlayersDetailsSuccess(data) {
  return {
    type: GET_PLAYER_DETAILS,
    payload: data
  };
}

export const createNewGame =
  ({
    title, coverImage, quizId, questions, owner,
    gameType, prize, courseId,
    durationBetweenAnswers,
    callback
  }) =>
    async (dispatch) => {
      dispatch(gameLoading('We are Gamefying your Quiz'));
      try {
        let downloadURL;
        if (coverImage) {
          const storageRef = ref(storage, `games/${title}-${new Date().getTime()}`);
          const upload = await uploadBytes(storageRef, coverImage);
          downloadURL = await getDownloadURL(upload.ref);
        } else {
          downloadURL = '';
        }
        const newGame = await addDoc(collection(db, 'games'), {
          name: title,
          quizId: quizId,
          questions: questions,
          owner: owner,
          gameType: gameType,
          prize: prize,
          courseId: courseId,
          coverImage: downloadURL,
          durationBetweenAnswers: Number(durationBetweenAnswers),
          durationBetweenQuestions:0,
          rangeMessages: [
            {
              message: "AWESOME",
              min: 90,
              max: 100,
            },
            {
              message: "Don’t worry!",
              min: 0,
              max: 90,
            },
          ],
        });

        console.log("Game", newGame.id)

        const docSnap = await getDoc(doc(db, 'games', newGame.id));

        if (docSnap.exists()) {
          dispatch(createNewGameSuccess(docSnap.data()));
        }

        if (callback) callback();
      } catch (error) {
        dispatch(gameError('An error occurred while creating the game'));
      }
    };
export const fetchGames = () => async (dispatch) => {
  dispatch(gameLoading('Getting your games...'));
  try {
    const userId = auth.currentUser.uid;

    const q = query(collection(db, 'games'), where('owner', '==', userId));
    const games = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      games.push({ gameId: doc.id, ...doc.data() });
      // doc.data() is never undefined for query doc snapshots
    });
    dispatch(fetchGamesSuccess(games));
  } catch (error) {
    dispatch(gameError("We couldn't find any games created by you"));
  }
};
export const setSelectedGame = (game) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_GAME,
    payload: game
  });
};

export const startNewGameroom =
  ({ game, capacity, callback }) =>
    async (dispatch) => {
      dispatch(gameLoading('Starting the game'));
      try {
        const newGame = await addDoc(collection(db, 'rooms'), {
          game: game.gameId,
          owner: game.owner,
          active: true,
          answers: {},
          scores: {},
          allPlayers: [],
          colors: {},
          capacity: capacity ? capacity : null,
          started: false,
          startDate: '',
          endDate: '',
          pinCode: generatePinCode(),
        });
        console.log(newGame.id)
        const docSnap = await getDoc(doc(db, 'rooms', newGame.id));

        if (docSnap.exists()) {
          dispatch(newGameroomSuccess({ [game.gameId]: { roomId: newGame.id, ...docSnap.data() } }));
        }

        if (callback) callback();
      } catch (error) {
        dispatch(gameError('An error occurred while creating the game'));
      }
    };
export const fetchActiveGames = () => async (dispatch) => {
  dispatch(gameLoading('Getting your active games...'));
  try {
    const userId = auth.currentUser.uid;
    const q = query(collection(db, 'rooms'), where('owner', '==', userId));
    const activeGames = {};
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      if (doc.data().active)
        Object.assign(activeGames, { [doc.data().game]: { roomId: doc.id, ...doc.data() } });
      // games.push({ gameId: doc.id, ...doc.data() });
      // doc.data() is never undefined for query doc snapshots
    });

    dispatch(fetchActiveGamesSuccess(activeGames));
  } catch (error) {
    dispatch(gameError("We couldn't find any active games created by you"));
  }
};
export const archiveGame =
  ({ gameId, archiveState }) =>
    async (dispatch) => {
      dispatch(gameLoading(`${archiveState ? 'Archiving game..' : 'Activating game...'}`));
      const ref = doc(db, 'games', gameId);

      try {
        await updateDoc(ref, {
          archived: archiveState
        });

        const docSnap = await getDoc(doc(db, 'games', gameId));

        if (docSnap.exists()) {
          dispatch(archiveSuccess({ gameId: gameId, ...docSnap.data() }));
        }
      } catch (error) {
        dispatch(gameError(`We could not ${archiveState ? 'archive' : 'activate'} this game`));
      }
    };
export const fetchPlayingGame = (id) => async (dispatch) => {
  dispatch(gameLoading('Getting game data'));
  const unsub = onSnapshot(doc(db, 'games', id), (doc) => {
    if (!doc.data()) {
      dispatch(gameError("We can't find your user"));
    }
    dispatch(fetchPlayingGameSuccess(doc.data()));
  });
  return () => unsub();
};

export const changeCurrentQuestion = (value) => async (dispatch) => {
  dispatch({
    type: CURRENT_QUESTION_UPDATE,
    payload: value
  });
};
export const fetchPlayRoom = (id) => async (dispatch) => {
  dispatch(gameLoading('Getting game data'));
  const unsub = onSnapshot(doc(db, 'rooms', id), (doc) => {
    if (!doc.data()) {
      dispatch(gameError("We can't find your user"));
    }
    dispatch(fetchPlayRoomSuccess(doc.data()));
  });
  return () => unsub();
};
export const startGame =
  ({ roomId, callback }) =>
    async (dispatch) => {
      dispatch(gameLoading('Starting the game'));
      const ref = doc(db, 'rooms', roomId);
      try {
        await updateDoc(ref, {
          started: true,
          startedAt: serverTimestamp(),
          questionState: serverTimestamp(),
          currentQuestion: 0,
          scores: {}
        });
        dispatch(success());
        if (callback) callback();
      } catch (error) {
        console.log(error);
        dispatch(gameError('We could not start the game'));
      }
    };
export const joinGame =
  ({ userId, room, roomId, userEmail, callback }) =>
    async (dispatch) => {
      dispatch(gameLoading('Joining the game'));
      const ref = doc(db, 'rooms', roomId);
      const allPlayers = room.allPlayers;
      const activePlayers = room.activePlayers;
      const colors = room.colors;
      const findInAllPlayers = allPlayers.find((player) => player === userId);
      const findInActivePlayers = activePlayers.find((player) => player === userId);
      const findColorOfPlayer = colors[userId];
      if (!findInAllPlayers) {
        allPlayers.push(userId);
      }
      if (!findInActivePlayers) {
        activePlayers.push(userId);
      }
      if (!findColorOfPlayer) {
        colors[userId] = getRandomColor();
      }
      try {
        await updateDoc(ref, {
          allPlayers: allPlayers,
          activePlayers: activePlayers,
          colors: colors
        });
        setDoc(
          doc(db, 'rooms', roomId, 'indices', 'users'),
          {
            [userId]: {
              name: userEmail,
              id: userId,
            }
          },
          { merge: true }
        )
        dispatch(success());
        if (callback) callback();
      } catch (error) {
        dispatch(gameError('We could not connect'));
      }
    };
export const incrementQuestionCount =
  ({ currentQuestion, roomId, callback }) =>
    async (dispatch) => {
      // dispatch(gameLoading('Next question'));
      const ref = doc(db, 'rooms', roomId);

      try {
        // await updateDoc(ref, {
        //   currentQuestion: currentQuestion + 1,
        //   questionState: new Date()
        // });
        dispatch(success());
        if (callback) callback();
      } catch (error) {
        dispatch(gameError('We could not go to the next question'));
      }
    };
export const sendQuestionResponse =
  ({
    currentQuestion,
    roomId,
    playerId,
    answer,
    prevScore = 0,
    currentQuestionStartTime,
    callback
  }) =>
    async (dispatch) => {
      // dispatch(gameLoading('Sending response'));
      const ref = doc(db, 'rooms', roomId);

      // console.log('startTime', currentQuestionStartTime);
      const currentTime = new Date().getTime();
      const startTime = new Date(currentQuestionStartTime * 1000).getTime();
      const timePassed = (currentTime - startTime) / 1000;
      const maxScore = 10;
      try {
        await updateDoc(ref, {
          [`answers.${playerId}.${currentQuestion}`]: { ...answer, time: timePassed },
          [`scores.${playerId}`]: answer.correct
            ? Math.round(maxScore - timePassed) + prevScore
            : prevScore
        });
        dispatch(success());
        if (callback) callback();
      } catch (error) {
        dispatch(gameError('We could not go to the next question'));
      }
    };

export const showGamesScreen =
  ({ callback }) =>
    async (dispatch) => {
      dispatch(handleGameScreenSuccess(true));
      setTimeout(() => {
        if (callback) {
          dispatch(handleGameScreenSuccess(false));
          callback();
        }
      }, 7000);
    };
export const endGame =
  ({ roomId, callback }) =>
    async (dispatch) => {
      const ref = doc(db, 'rooms', roomId);
      try {
        await updateDoc(ref, {
          endDate: new Date(),
          active: false
        });
        dispatch(success());
        if (callback) callback();
      } catch (error) {
        dispatch(gameError('We could not end the game'));
      }
    };
export const getPlayersDetails =
  ({ players, callback }) =>
    async (dispatch) => {
      try {
        const q = query(collection(db, 'users'), where('uid', 'in', players));
        const playersArr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          playersArr.push({ [doc.id]: { ...doc.data() } });
        });

        var returnPlayers = playersArr.reduce((obj, item) => {
          return Object.assign(obj, { ...item });
        }, {});
        dispatch(getPlayersDetailsSuccess(returnPlayers));
        if (callback) callback();
      } catch (error) {
        dispatch(gameError('We could not get the players information'));
      }
    };
