import MainButton from '../../ui/buttons/MainButton';
import Input from '../Input';
import { MailIcon, LockClosedIcon } from '@heroicons/react/solid';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { signin } from '../../../redux/modules/auth/actions';
const SigninForm = ({ signupLink, recoveryLink, redirectLink }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const signinCallback = () => {
    navigate(redirectLink ? redirectLink : '/dashboard');
  };
  const submitSignin = (data) => {
    dispatch(signin({ email: data.email, password: data.password, callback: signinCallback }));
  };
  return (
    <>
      <div className="flex items-center justify-center py-12 px- sm:px-6 lg:px-8 w-full">
        <div className="max-w-6xl w-full space-y-8">
          <form className="mt-8 space-y-6" action="#" method="POST">
            <div>
              <Controller
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <Input
                      Icon={<MailIcon className="h-5 w-5 text-gameBlue" />}
                      label="email"
                      htmlFor="email"
                      placeholder="account@gamify.com"
                      onChange={(e) => onChange(e)}
                    />
                  );
                }}
                name="email"
                rules={{
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    message: 'Please use a valid email address'
                  },
                  required: {
                    value: true,
                    message: 'Please add an email address'
                  }
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: '1rem' }}>
              {errors.email && errors.email.message}
            </p>
            <div>
              <Controller
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <Input
                      Icon={<LockClosedIcon className="h-5 w-5 text-gameBlue" />}
                      label="password"
                      htmlFor="password"
                      placeholder="Your password"
                      onChange={(e) => onChange(e)}
                      type="password"
                    />
                  );
                }}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: 'The password is required'
                  },
                  minLength: {
                    value: 6,
                    message: 'The password must be at least 6 characters'
                  }
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: '1rem' }}>
              {errors.password && errors.password.message}
            </p>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link
                  to={signupLink ? signupLink : '/signup'}
                  className="font-medium text-gameBlue hover:text-opacity-75">
                  Don{"'"}t have an account?
                </Link>
              </div>
              <div className="text-sm">
                <Link
                  to={recoveryLink ? recoveryLink : '/recover-password'}
                  className="font-medium text-gameBlue hover:text-opacity-75">
                  Forgot password?
                </Link>
              </div>
            </div>
            <div>
              <MainButton
                type="submit"
                title="Sign in"
                width="w-full"
                action={handleSubmit(submitSignin)}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default SigninForm;
