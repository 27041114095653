import {
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_SIGN_UP,
  IS_AUTH,
  AUTH_SIGN_OUT,
  AUTH_SIGN_IN,
  AUTH_ERROR_CLEAR,
  AUTH_FORGOT_PASSWORD
} from './constants';
import { auth, db } from '../../../config/firebase-config';
import { setDoc, doc } from 'firebase/firestore';
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth';

export function authError(data) {
  return {
    type: AUTH_ERROR,
    payload: data
  };
}

export function authLoading(data) {
  return {
    type: AUTH_LOADING,
    payload: data
  };
}
export function signupSuccess(data) {
  return {
    type: AUTH_SIGN_UP,
    payload: data
  };
}
export function isAuthChanged(data) {
  return {
    type: IS_AUTH,
    payload: data
  };
}

export function signoutSuccess() {
  return {
    type: AUTH_SIGN_OUT
  };
}
export function signinSuccess(data) {
  return {
    type: AUTH_SIGN_IN,
    payload: data
  };
}
export function clearErrors() {
  return {
    type: AUTH_ERROR_CLEAR
  };
}
export function success() {
  return {
    type: AUTH_FORGOT_PASSWORD
  };
}
export const isAuth = () => async (dispatch) => {
  const sub = onAuthStateChanged(auth, (user) => {
    dispatch(isAuthChanged(user));
  });
  return () => sub;
};

export const signup =
  ({ email, password, student, callback }) =>
  async (dispatch) => {
    dispatch(authLoading('Signing you up'));
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      dispatch(signupSuccess(userCredential.user));

      await setDoc(doc(db, 'users', userCredential.user.uid), {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        role: student ? 'Student' : null
      });

      if (callback) callback();
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          dispatch(authError('The email address is already in use'));
          break;
        case 'auth/invalid-email':
          dispatch(authError('The email address is invalid'));
          break;
        default:
          console.error(error);
          break;
      }
    }
  };

export const signin =
  ({ email, password, callback }) =>
  async (dispatch) => {
    dispatch(authLoading('Signing you in'));
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      dispatch(signupSuccess(userCredential.user));

      if (callback) callback();
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          dispatch(authError('The user could not be found'));
          break;
        case 'auth/wrong-password':
          dispatch(authError('Wrong password'));
          break;
        case 'auth/too-many-requests':
          dispatch(authError('Too many requests. Please try again later'));
          break;
        default:
          dispatch(authError(error.code));
      }
    }
  };
export const signoutUser =
  ({ callback }) =>
  async (dispatch) => {
    dispatch(authLoading('Signing you out'));
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        dispatch(signoutSuccess());
        if (callback) callback();
      })
      .catch(() => {
        dispatch(authError('An error occurred while signing out'));
      });
  };
export const sendRecoveryEmail = (data) => (dispatch) => {
  dispatch(authLoading());
  sendPasswordResetEmail(auth, data.email)
    .then(() => {
      dispatch(success());
    })
    .catch(() => {
      dispatch(authError('An error occurred while sending the email'));
    });
};
export const resetPassword =
  ({ code, password, callback }) =>
  (dispatch) => {
    dispatch(authLoading('Sending you the recovery email'));

    confirmPasswordReset(auth, code, password)
      .then(() => {
        dispatch(success());
        if (callback) callback();
      })
      .catch((err) => {
        if (err.code === 'auth/invalid-action-code') {
          dispatch(authError('The reset code expired'));
        } else {
          dispatch(authError('An error occurred while reseting your password'));
        }
      });
  };
export const authErrorClear = () => async (dispatch) => {
  dispatch(clearErrors());
};
