import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
// import MainButton from '../../buttons/MainButton';
import { XIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../../../../redux/modules/ui/actions';
const Modal = ({ children, extraClasses }) => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.ui);
  const closeModal = () => {
    dispatch(hideModal());
  };
  return (
    <Transition.Root show={modal.visible} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-10 inset-0 overflow-y-auto ${extraClasses}`}
        onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="ml-4 flex-shrink-0 flex justify-end">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none "
                  onClick={() => {
                    closeModal();
                  }}>
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {children}
              <div className="mt-5 sm:mt-6">
                {/* <MainButton title={'Go back to dashboard'} action={() => closeModal()} /> */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
