import React, { useEffect, useMemo, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/outline';
// import { useDispatch } from 'react-redux';
// import { getPlayersDetails } from '../../../redux/modules/games/actions';
const LeaderBoard = ({ scores, playerDetails }) => {
  const [activeScores, setActiveScores] = useState([]);

  useEffect(() => {
    if (scores)
      setActiveScores(
        Object.entries(scores)
          .map((entry) => {
            return { id: entry[0], score: entry[1] };
          })
          .sort((player1, player2) => player2.score - player1.score)
      );
  }, []);
  const renderPlayer = useMemo(() => {
    return activeScores.map((score) => {
      return (
        <li key={score.id} className="py-3 flex justify-between items-center">
          <div className="flex items-center">
            <span className="w-8 h-8 rounded-full bg-gameBlue flex justify-center items-center">
              <UserCircleIcon className="h-5 w-5 text-white" />
            </span>
            <p className="ml-4 text-sm font-medium text-gray-900">
              {playerDetails[score.id].email}
            </p>
          </div>
          <button
            type="button"
            className="ml-6 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {score.score}
          </button>
        </li>
      );
    });
  }, [activeScores]);
  return (
    <div className="w-full">
      <div className="w-full p-4 flex justify-center items-center bg-gray-100 rounded-lg">
        <h1 className="text-3xl text-black font-bold ">Leaderboard</h1>
      </div>
      <ul className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
        {renderPlayer}
      </ul>
    </div>
  );
};

export default LeaderBoard;
