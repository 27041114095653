const GridList = ({ data, Component }) => {
  if (data?.length > 0) {
    return (
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3">
        {data.map((item, i) => (
          <li key={i} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <Component item={item} />
          </li>
        ))}
      </ul>
    );
  }
  return <></>;
};

export default GridList;
