import React from 'react';
import FirstLoginForm from '../../components/forms/FirstLoginForm';
import LogoPair from '../../components/ui/common/LogoPair';

const NewUser = () => {
  return (
    <div className="max-w-6xl mx-auto flex justify-center items-center flex-col p-4">
      <LogoPair />
      <div className="w-full justify-center items-center flex p-4 text-center">
        <h1 className="text-xl font-bold">
          Connect your school and your account Canvas account to Gamify
        </h1>
      </div>
      <FirstLoginForm />
    </div>
  );
};

export default NewUser;
