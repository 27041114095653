import {
  GET_COURSES,
  CANVAS_LOADING,
  CANVAS_ERROR,
  GET_USER_CANVAS_DATA,
  GET_QUIZZES,
  CANVAS_ERROR_CLEAR,
  GET_QUIZ_QUESTIONS,
  GET_ROLE
} from './constants';

export const initialState = {
  userData: {},
  courses: [],
  quizzes: {},
  questions: [],
  loading: '',
  error: ''
};

const coursesError = (state, action) => {
  const newState = { ...state };

  newState.loading = '';
  newState.error = action.payload;

  return newState;
};

const coursesLoading = (state, action) => {
  const newState = { ...state };

  newState.loading = action.payload;
  newState.error = '';
  return newState;
};

function getCoursesData(state, action) {
  const newState = { ...state };
  newState.courses = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
}
function userCanvasData(state, action) {
  const newState = { ...state };
  newState.userData = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
}

function getQuizzes(state, action) {
  const newState = { ...state };
  if (!(Object.keys(action.payload)[0] in newState.quizzes)) {
    newState.quizzes = { ...newState.quizzes, ...action.payload };
  }
  newState.loading = '';
  newState.error = '';
  return newState;
}
function getQuizQuestions(state, action) {
  const newState = { ...state };
  newState.questions = action.payload;
  newState.loading = '';
  newState.error = '';
  return newState;
}
function errorClear(state) {
  const newState = { ...state };
  newState.error = '';
  return newState;
}
function success(state) {
  const newState = { ...state };
  newState.loading = '';
  newState.error = '';
  return newState;
}

const handlers = {
  [CANVAS_ERROR]: coursesError,
  [CANVAS_LOADING]: coursesLoading,
  [GET_COURSES]: getCoursesData,
  [GET_USER_CANVAS_DATA]: userCanvasData,
  [GET_QUIZZES]: getQuizzes,
  [CANVAS_ERROR_CLEAR]: errorClear,
  [GET_QUIZ_QUESTIONS]: getQuizQuestions,
  [GET_ROLE]: success
};

export default function canvas(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
