import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateGameForm from '../../components/forms/CreateGameForm';
import QuizCard from '../../components/ui/cards/QuizCard';
import GridList from '../../components/ui/lists/GridList';
import Modal from '../../components/ui/Modals/Modal';

const SingleCourse = () => {
  const { id } = useParams();
  const { quizzes } = useSelector((state) => state.canvas);
  const [currentQuizzes, setCurrentQuizzes] = useState([]);
  useEffect(() => {
    setCurrentQuizzes(quizzes[id]);
  }, []);
  return (
    <>
      {currentQuizzes.length > 0 && <GridList data={currentQuizzes} Component={QuizCard} />}
      <Modal>
        <CreateGameForm courseId={id} />
      </Modal>
    </>
  );
};

export default SingleCourse;
