import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
import NewUserLayout from '../../layouts/NewUserLayout';

const RequireAuth = ({ allowedRole }) => {
  const { userLocal } = useSelector((state) => state.user);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  const returnLayout = () => {
    if (isAuthenticated) {
      if (userLocal?.email) {
        if (userLocal.accessToken && userLocal.schoolUrl && userLocal.role !== 'Student') {
          return <DashboardLayout />;
        } else if (allowedRole === userLocal.role) {
          return <NewUserLayout />;
        } else {
          return <NewUserLayout />;
        }
      }
      return <div>Loading</div>;
    }
  };

  console.log(userLocal);

  //   auth?.roles?.find((role) => allowedRoles?.includes(role));
  return allowedRole?.includes(userLocal?.role) ? (
    returnLayout()
  ) : isAuthenticated ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default RequireAuth;
