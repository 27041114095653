import MainButton from '../../ui/buttons/MainButton';
import Input from '../Input';
import { LockClosedIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/modules/auth/actions';
import { useNavigate } from 'react-router-dom';
const RestePasswordForm = ({ code }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const resetCallback = () => {
    navigate('/signin');
  };
  const submit = (data) => {
    dispatch(resetPassword({ password: data.password, code: code, callback: resetCallback }));
  };

  return (
    <>
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 w-full">
        <div className="max-w-6xl w-full space-y-8">
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <Controller
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <Input
                      Icon={<LockClosedIcon className="h-5 w-5 text-gameBlue" />}
                      label="password"
                      htmlFor="password"
                      placeholder="Your password"
                      onChange={(e) => onChange(e)}
                      type="password"
                    />
                  );
                }}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: 'The password is required'
                  },
                  minLength: {
                    value: 6,
                    message: 'The password must be at least 6 characters'
                  }
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: '1rem' }}>
              {errors.password && errors.password.message}
            </p>

            <div>
              <MainButton
                type="submit"
                title="Reset password"
                width="w-full"
                action={handleSubmit(submit)}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default RestePasswordForm;
