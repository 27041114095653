import React from 'react';
import gamifyLogo from '../../../assets/images/logo-big.png';
import { QuestionMarkCircleIcon, PuzzleIcon } from '@heroicons/react/solid';
import MainButton from '../../ui/buttons/MainButton';
const GamePreview = ({ playingGame, setStartGame }) => {
  return (
    <>
      <div className="w-full rounded-xl">
        {playingGame.coverImage ? (
          <img
            className={'w-full max-h-64 object-cover rounded-xl'}
            src={playingGame.coverImage}
            alt={playingGame.name}
          />
        ) : (
          <img
            className={'w-full max-h-64 object-cover rounded-xl'}
            src={gamifyLogo}
            alt={playingGame.name}
          />
        )}
      </div>

      <div className="w-full pb-4 px-2">
        <p className="text-sm text-gray-400 font-bold py-2">{playingGame.gameType}</p>
        <h2 className="text-3xl font-bold">{playingGame.name}</h2>
      </div>
      <div className="w-full p-2 bg-backgroundAccent rounded-xl flex justify-center items-center">
        <div className="w-1/2 flex justify-around items-center">
          <QuestionMarkCircleIcon className="h-8 w-8 text-gameBlue" />
          {playingGame.questions?.length} questions
        </div>
        <div className="relative">
          <div className="absolute h-full inset-0 flex items-center">
            <div className="h-6 w-1 border-l border-gray-300" />
          </div>
        </div>
        <div className="w-1/2 flex justify-around items-center">
          <PuzzleIcon className="h-8 w-8 text-accentYellow" />
          +100 points
        </div>
      </div>
      <div className="w-full  flex justify-center items-center absolute inset-x-0 bottom-0 py-4">
        <MainButton title="Start Game" action={() => setStartGame()} width="w-2/3" />
      </div>
    </>
  );
};

export default GamePreview;
